<div mat-dialog-content>
    <button mat-icon-button class="close-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
    <mat-card class="h-full w-full">
        <mat-card-header class="flex justify-center flex-col w-auto mb-5">
            <!-- <mat-card-title class="text-center">{{json_encuesta_data.aen_titulo}}</mat-card-title> -->
            <!-- ¡Te queremos conocer más! -->
            <mat-card-title class="text-center mb-4">{{json_data?.titulo}}</mat-card-title>
            <!-- <mat-card-subtitle class="text-center">{{subtitle}}</mat-card-subtitle> -->
            <!-- <div class="pb-4">
              <mat-progress-bar mode="determinate" value={{progress_value}}></mat-progress-bar>
            </div> -->
        </mat-card-header>
        <mat-card-content class="flex w-auto" style="height: auto; overflow: hidden;">
            <form class="w-full text-center" style="overflow-y: auto;" #formContainer>
                <!-- Datos Basicos -->
                <app-basic-data *ngIf="step == 1" [formValues]="json_data.encuesta?.DatosBasicos" [stepForm]="basic_data"></app-basic-data>
                <!-- ESTILO DE VIDA -->
                <!-- <app-lifestyle *ngIf="step == 2" [formValues]="json_encuesta_data?.EstiloDeVida" [stepForm]="lifestyle" (subtitle)="getSubtitle($event)"></app-lifestyle> -->
                <!--  Tecnología y Comunicación -->
                <!-- <app-tech-comm *ngIf="step == 3" [formValues]="json_encuesta_data?.TecnologiaYComunicacion" [stepForm]="tech_comunication" (subtitle)="getSubtitle($event)"></app-tech-comm> -->
                <!-- Ocio y tiempo libre  -->
                <!-- <app-free-time *ngIf="step == 4" [formValues]="json_encuesta_data?.OcioYTiempoLibre" [stepForm]="free_time" (subtitle)="getSubtitle($event)"></app-free-time> -->
            </form>
        </mat-card-content>
        <mat-card-actions class="flex justify-between">
            <div class="p-5">
                <button *ngIf="step != 1" mat-flat-button color="accent" (click)="backStep()">Atrás</button>
            </div>
            <div class="p-5">
                <!-- <button *ngIf="step != 4" mat-flat-button color="primary" (click)="nextStep()">Siguiente</button> -->
                <button *ngIf="step == 1" mat-flat-button color="primary" (click)="onSubmit()">Enviar</button>
            </div>
        </mat-card-actions>

    </mat-card>
</div>
