import { Component, ViewChild, ElementRef  } from '@angular/core';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';

//services
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { AlertasService } from '../../../core/services/system/alerts.service';
import { UsuarioService } from 'src/app/core/services/system/usuario.service';
import { IpInfoService } from 'src/app/core/services/system/ip-info.service';
import { TimeService } from 'src/app/core/services/system/time.service';
import { SurveyUserService } from 'src/app/core/services/survey/surveyUser.service';

//utils
import { GeneralUtils } from 'src/app/shared/utils/general.utils';
import { UserLogin } from '../../interfaces/user.interface';
import { UserService } from 'src/app/core/services/user/user.service';
import { AlertsResponse } from '../../interfaces/alerts.interface';
import { UploadFotoResponse } from 'src/app/shared/interfaces/usuario.interface';
import { ModalAyudaComponent } from '../../components/modal-ayuda/modal-ayuda.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { Survey } from '../../interfaces/survey.interface';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;

  isLoading: boolean = true;
  alerts: any[] = [];
  hasUnreadAlerts: boolean = false;
  isOpen : boolean = false;
  isFooterClosed: boolean = false;
  userCountry: string = '';
  dataSurveys: Survey[] = []
  isAlertMenuOpen: boolean = false;

  isLoadingFoto: boolean = false;
  imageBase64 : string = '';

  user: UserLogin = {
    token: '',
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    userPoints: 0,
    // image_foto: ''
  };

  constructor(
    private authService: AuthService,
    private userServie : UserService,
    private ipInfoService: IpInfoService,
    private AlertasService: AlertasService,
    private usuarioService: UsuarioService,
    private surveyUserService: SurveyUserService,
    private generalUtils: GeneralUtils,
    private router: Router,
    public dialog:MatDialog,
    private spinner: NgxSpinnerService
  ) {
    this.user = userServie.getCurrentUser();
    this.isFooterClosed = localStorage.getItem('footerClosed') === 'true';
  }

  openDialog(){
    const dialogRef = this.dialog.open(ModalAyudaComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  isLoadingFotoService = true;

  ngOnInit(): void {
    this.getFotoPerfil();
    // imageBase64
    this.getUserAlerts();
    this.getUserPoints();
    this.userServie.currentUserScore.subscribe(score => {
      this.user.userPoints = score;
    });
    this.checkCountryLogout();
    this.hideRecaptcha();
  }

  checkCountryLogout() {
    
  }

  sidebarNavigation(pUrl : string){ // Navegación de la barra lateral
    this.router.navigate([pUrl]);
    this.closeNotificationMenu();
  }

  hideRecaptcha(): void {
    const recaptchaBadge = document.querySelector('.grecaptcha-badge');
    if (recaptchaBadge) {
      (recaptchaBadge as HTMLElement).style.display = 'none';
    }
  }

  userLogout(){
    this.generalUtils.Toast.fire({
      icon: 'success',
      title: 'Sesión cerrada exitosamente!',
      timer: 2500
    });
    this.authService.logout();
  }

  userUpdateInfo(){
    this.router.navigate(['/usuario/actualizar'])
  }

  getUserPoints() {
    this.isLoading = true;
    this.surveyUserService.refreshAllSurvey([]).subscribe(res=>{
      this.userServie.getPoints().subscribe(res => {
        if (res.status === 200) {
          this.userServie.updatePoints(res.data);
          this.isLoading = false;
        }
      });
    });
  }

  getUserAlerts() {
    this.isLoading = true;
    this.AlertasService.getUserAlerts().subscribe(
      (response: AlertsResponse) => {
        this.alerts = response.data;
        this.checkIfHasUnreadAlerts();
        this.isLoading = false;
        this.alerts.map((alert) => ({
          ...alert,
          timeElapsed: new TimeService().timeSince(alert.alt_fecha_registro)
        }))
      },
      (error) => {
        console.error('Error fetching user alerts:', error);
      }
    );
  }

  checkIfHasUnreadAlerts() {
    this.hasUnreadAlerts = this.alerts.some((alert) => alert.e_alt_id === 1)
  }

  toggleNotificationsMenu() {
    this.isAlertMenuOpen = !this.isAlertMenuOpen;
  }

  closeNotificationMenu() {
    this.isAlertMenuOpen = false;

    // Llama a la API para marcar alertas como leídas en la base de datos.
    this.AlertasService.markAlertAsRead().subscribe(
      (response: AlertsResponse) => {

        // Recorre el array alerts para cambiar a leídas el estado de las alertas en el front.
        this.alerts = this.alerts.map((alert) => {
          alert.e_alt_id = 2;
          return alert;
        })

        // Cambia el estado bool de hasUnreadAlerts a false (no hay alertas por leer).
        this.hasUnreadAlerts = false;

      },
      (error) => {
        console.error('Error marking alerts as read:', error);
      }
    )
  }

  toggleSidebar() {
    this.isOpen = !this.isOpen;
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.isLoadingFotoService = true;
      this.usuarioService.uploadFoto(file).subscribe(
        (response: UploadFotoResponse) => {
          const { cod_msg, data, msg, status } = response;
          if (status === 200) {
            this.generalUtils.Toast.fire({title: msg, icon: 'success', timer: 2500});
            this.imageBase64 = data;
          } else {
            this.generalUtils.Toast.fire({title: msg, icon: 'error', timer: 2500});
          }
          this.isLoadingFotoService = false;
        },
        (error) => {
          this.generalUtils.Toast.fire({title: 'Ups, error al subir la imagen.', icon: 'error', timer: 2500});
          this.isLoadingFotoService = false;
        }
      );
    }
  }

  getFotoPerfil(){
    this.usuarioService.getFotoPerfil().subscribe(
      (response: UploadFotoResponse) => {
        const { cod_msg, data, msg, status } = response;
        if (status === 200) {
          this.imageBase64 = data;
        } else {
          this.generalUtils.Toast.fire({title: msg, icon: 'error', timer: 2500});
        }
        this.isLoadingFotoService = false;
      },
      (error) => {
        this.generalUtils.Toast.fire({title: 'Ups, error al subir la imagen.', icon: 'error', timer: 2500});
        this.isLoadingFotoService = false;
      }
    );
  }

  // Para que se cierre automáticamente el sidebar al hacer click en un enlace (mobile)
  isMobile(): boolean {
    return window.innerWidth <= 640;
  }

  onMobileSidebarToggle(): void {
    if (this.isMobile()) {
      this.toggleSidebar();
    }
  }

  closePopUpFooter() {
    this.isFooterClosed = true;
    sessionStorage.setItem('footerClosed', 'true');
  }

  refreshUserPoints(){
    this.isLoading = true;
    this.getUserPoints();
  }

  async refreshAllSurveys() {
    this.surveyUserService.refreshAllSurvey([]).subscribe(res=>{
      
    })
  }

}
