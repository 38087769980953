import { Component } from '@angular/core';
//services
import { SurveyService } from 'src/app/core/services/survey/survey.service'
import { SurveyUserService } from 'src/app/core/services/survey/surveyUser.service'
import { UserService } from 'src/app/core/services/user/user.service';

//interfaces
import { Survey } from 'src/app/shared/interfaces/survey.interface';
//utils
import { GeneralUtils } from 'src/app/shared/utils/general.utils'
import { DefaultLayoutComponent } from 'src/app/shared/layout/default-layout/default-layout.component';
import { MatDialog } from '@angular/material/dialog';
import { FirstSurveyAlertModalComponent } from './components/first-survey-alert-modal/first-survey-alert-modal.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  userStatus: number|null = 0

  dataSurveys: Survey[] = []
  constructor(
    private defaultLayoutComponent : DefaultLayoutComponent,
    private userService: UserService,
    private dialog: MatDialog
  ) 
  {  }

  ngOnInit(): void {
    this.defaultLayoutComponent.getUserAlerts();
    this.getStatus()
    const cameFromLogin = sessionStorage.getItem('userFromLogin');
    if (cameFromLogin === 'true' && this.userStatus === 3) {
      this.openUserModal();
      sessionStorage.removeItem('userFromLogin');
    }
  }

  getStatus(){
    this.userService.getUserStatus().subscribe(status => {
      this.userStatus = status
    });
  }

  openUserModal(): void {
    this.dialog.open(FirstSurveyAlertModalComponent,{
      width: '95vw',
      maxWidth: '95vw',
      panelClass: 'full-width-dialog'});
  }

}
