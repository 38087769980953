<!-- <div class="h-28 bg-cyan-500 flex">
  <div class="flex-1 flex justify-start">Hola</div>
  <div class="flex-1 flex justify-end">Chao</div>

</div> -->
<div class="background-image">
  <div class="flex flex-col-reverse md:flex-row min-h-screen">

    <!-- formulario -->
    <div class="form-container mt-5 flex-1 flex items-center justify-center md:justify-start md:pl-32 2xl:pl-64md:order-1">
    <div class="w-[85%] md:w-[430px]" >
      <div [ngClass]="{'hidden': statusLogin!=0, 'block': statusLogin==0}">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()"
          class="form-class md:flex md:items-center mb-6  mx-auto bg-white-opacity rounded-3xl">

          <!-- cademito -->
          <a class="cademito flex justify-center mb-5" href="http://cademonline.cl/#inicio">
            <img src="../../../assets/images/cademito.png" alt="cademito">
          </a>

          <h2 class="text-center font-bold text-4xl">¡Bienvenido!</h2>

          <div class="w-full mb-6 mt-6">
            <input formControlName="usuario"
              class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="usuario" type="text" placeholder="Email">
            <div *ngIf="loginForm.get('usuario')?.invalid && loginForm.get('usuario')?.touched"
              class="text-red-600 text-xs absolute">
              El email es obligatorio.
            </div>
          </div>

          <div class="w-full mb-14 relative">
            <input [type]="hidePassword ? 'password' : 'text'" formControlName="password" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="usu_password" placeholder="Contraseña" />
            <button type="button" (click)="togglePasswordVisibility()" class="absolute right-4 top-3" style="width: 24px; height: 24px; border: none; background: none; cursor: pointer;">
              <mat-icon style="color: gray;">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched" class="text-red-600 text-xs absolute">
              La contraseña es obligatoria.
            </div>
          </div>

          <!-- <div class="w-full mb-6">
            <input formControlName="password"
              class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="password" type="password" placeholder="Contraseña">
            <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched"
              class="text-red-600 text-xs absolute">
              La contraseña es obligatoria.
            </div>
          </div> -->

          <div class="text-center mb-7">
            <span class="text-sm font-bold text-color4 hover:text-color4-h cursor-pointer pr-1"
              (click)="cambiarStatusEnCambiarContrasena()">Olvidé mi contraseña</span>
          </div>


          <button type="submit"  [ngClass]="{'disabled': loginForm.invalid}"
            class="primary-button w-full h-12 text-center cursor-pointer text-xs md:text-sm">INICIAR SESIÓN</button>
          <div class="mt-4 text-center">
            <span class="text-sm text-gray-600 pr-1">¿No tienes una cuenta?</span>
            <a routerLink="/register" class="text-sm text-indigo-600 hover:text-indigo-500">Regístrate</a>
          </div>

          <div class="mt-4 text-center">
            <p (click)="irVerificarCuenta()" class="text-sm text-indigo-600 hover:text-indigo-500 cursor-pointer">Verifica tu cuenta</p>
          </div>
          <div *ngIf="loading">
            <mat-spinner [diameter]="30" [strokeWidth]="6"></mat-spinner>
          </div>

        </form>
    </div>
    <div [ngClass]="{'hidden': statusLogin!=1, 'block': statusLogin==1}">
        <form [formGroup]="solicitudCambioForm" (ngSubmit)="onSubmitSolicitudCambio()"
          class="form-class md:flex md:items-center mb-6  mx-auto bg-white-opacity rounded-lg">
          <!-- class="form-class md:flex md:items-center mb-6 mx-auto bg-white-opacity rounded-lg py-80"> -->
          <!-- <h2 class="text-center font-bold text-4xl">¡Bienvenido!</h2> -->

           <!-- cademito -->
           <!-- <a class="cademito flex justify-center mb-5" href="http://cademonline.cl/#inicio">
            <img src="../../../assets/images/cademito.png" alt="cademito">
          </a> -->

          <h2 class="text-center font-bold text-3xl">Recuperar contraseña</h2>
          <p style="font-size: 14px !important;" class="text-center text-gray-600 mt-2">Ingresa tu correo electrónico porque te enviaremos un código para que puedas recuperar tu contraseña</p>

          <div class="w-full mb-6">
            <input formControlName="email"
              class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="usuario" type="text" placeholder="Email">
            <div *ngIf="solicitudCambioForm.get('email')?.invalid && solicitudCambioForm.get('email')?.touched else emailErroneo"
              class="text-red-600 text-xs absolute">
              El email es obligatorio.
            </div>
            <ng-template #emailErroneo>
              <div class="text-red-600 text-xs absolute">

              {{msgSolicitarContrasena}}
              </div>
          </ng-template>
          </div>




          <button type="submit"  [ngClass]="{'disabled': solicitudCambioForm.invalid}"
            class="primary-button w-full h-12 text-center cursor-pointer">Enviar Correo</button>
          <div class="mt-4 text-center cursor-pointer" (click)="cancelarActualizarContrasena()">
            <span class="text-sm text-indigo-600 hover:text-indigo-500">Volver a iniciar sesión</span>
          </div>

          <div *ngIf="loading" class="mt-8">
            <mat-spinner [diameter]="40" [strokeWidth]="6"></mat-spinner>
          </div>

        </form>
    </div>
    <div [ngClass]="{'hidden': statusLogin!=2, 'block': statusLogin==2}">
        <form [formGroup]="actualizarPasswordForm" (ngSubmit)="onSubmitActualizarPassword()"
          class="form-class md:flex md:items-center mb-6  mx-auto bg-white-opacity rounded-lg">
          <h2 class="text-center font-bold text-4xl">Recuperar contraseña</h2>

          <div class="w-full mb-6 mt-12">
            <input formControlName="code"
              class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="code" type="text" placeholder="Codigo de verificacion">
            <div *ngIf="actualizarPasswordForm.get('code')?.invalid && actualizarPasswordForm.get('code')?.touched"
              class="text-red-600 text-xs absolute">
              El codigo de verificacion es obligatorio.
            </div>
          </div>

          <div class="w-full mb-14 relative">
            <input [type]="hidePasswordRecovery ? 'password' : 'text'" formControlName="contrasena" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="usuario" placeholder="Contraseña" />
            <button type="button" (click)="togglePasswordVisibilityRecovery()" class="absolute right-4 top-3" style="width: 24px; height: 24px; border: none; background: none; cursor: pointer;">
              <mat-icon style="color: gray;">{{ hidePasswordRecovery ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <div *ngIf="actualizarPasswordForm.get('contrasena')?.invalid && actualizarPasswordForm.get('contrasena')?.touched" class="text-red-600 text-xs absolute">
              La contraseña debe contener mínimo 6 caracteres y una mayúscula.
            </div>
          </div>

          <div class="w-full mb-14 relative" *ngIf="actualizarPasswordForm.get('contrasena')?.valid">
            <input [type]="hideRepeatedPassword ? 'password' : 'text'" formControlName="repetirContrasena" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="repeatPassword" placeholder="Repetir contraseña"/>
            <button type="button" (click)="toggleRepeatedPasswordVisibility()" class="absolute right-4 top-3" style="width: 24px; height: 24px; border: none; background: none; cursor: pointer;">
              <mat-icon style="color: gray;">{{ hideRepeatedPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <div *ngIf="actualizarPasswordForm.get('contrasena')?.value !== actualizarPasswordForm.get('repetirContrasena')?.value" class="text-red-600 text-xs absolute">
              Las contraseñas no coinciden.
            </div>
          </div>
          <!-- <div class="w-full mb-6 ">
            <input formControlName="contrasena"
              class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="usuario" type="password" placeholder="Contraseña">
            <div *ngIf="actualizarPasswordForm.get('contrasena')?.invalid && actualizarPasswordForm.get('contrasena')?.touched"
              class="text-red-600 text-xs absolute">
            La contraseña es obligatorio.
            </div>
          </div> -->

          <!-- <div class="w-full mb-6 ">
            <input formControlName="repetirContrasena"
              class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="repeatPassword" type="password" placeholder="Repetir Contraseña">
              <div *ngIf="actualizarPasswordForm.get('contrasena')?.value !== actualizarPasswordForm.get('repetirContrasena')?.value" class="text-red-600 text-xs absolute">
                Las contraseñas no coinciden.
              </div>
          </div> -->

          <div *ngIf="msgActualizarContrasena" class="text-red-600 w-full text-xs ">
            {{msgActualizarContrasena}}
          </div>


          <button type="submit"  [ngClass]="{'disabled': actualizarPasswordForm.invalid}"
            class="primary-button w-full h-12 text-center cursor-pointer">Cambiar Contraseña</button>
          <div class="mt-4 text-center cursor-pointer" (click)="cancelarActualizarContrasena()">
            <span class="text-sm text-indigo-600 hover:text-indigo-500">Volver a iniciar sesión</span>
          </div>

          <div *ngIf="loading" class="mt-1">
            <mat-spinner [diameter]="40" [strokeWidth]="6"></mat-spinner>
          </div>

        </form>
    </div>
    </div>
    </div>

      <!-- logo -->
      <div class="hidden md:block">
          <div class="md:flex-1 flex justify-end md:order-2">
            <a href="http://cademonline.cl/#inicio">
              <img src="assets/images/logo_cadem_online_blanco.png" alt="Logo" class="pr-5 pt-5 max-w-md max-h-28">
            </a>
          </div>
      </div>

  </div>
</div>





