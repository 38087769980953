import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor() { }

  timeSince(date: Date): string {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - new Date(date).getTime()) / 1000);

    let interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + ' años';
    }

    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' meses';
    }

    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' días';
    }

    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' horas';
    }

    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' minutos';
    }

    return Math.floor(seconds) + ' segundos';
  }
}
