import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// services

// utils
import { GeneralUtils } from 'src/app/shared/utils/general.utils';

@Component({
  selector: 'app-redirect-to-limesurvey',
  templateUrl: './redirect-to-limesurvey.component.html',
  styleUrls: ['./redirect-to-limesurvey.component.css']
})
export class RedirectToLimeSurveyComponent  {
  constructor(private route: ActivatedRoute
              , private sanitizer: DomSanitizer) {}

  isValidSid : boolean = false;
  isloading : boolean = true;
  urlLimeSurvey: SafeResourceUrl = "https://s6.cademonline.cl/index.php";

  ngOnInit() : void {
    this.route.queryParams.subscribe(params => {
      const sid = params["key"];
      const token = params["token"];
      if ( sid && sid.length > 0) {
        this.isValidSid = true;
        if (token && token.length > 0) {
          this.urlLimeSurvey = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.urlLimeSurvey}/${sid}?token=${token}&lang=es`);
        } else {
          this.urlLimeSurvey = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.urlLimeSurvey}/${sid}?lang=es`);
        }
        this.isloading = false;
      } else {
        this.isloading = false;
      }
    });
  }
  
}

