import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {HttpClient} from "@angular/common/http"

// Environments / Interfaces
import { environment } from 'src/app/environments/environment';
import { ResponseData, FirstSurveyResponseSave } from 'src/app/shared/interfaces/services.interface';

@Injectable({
  providedIn: 'root'
})
export class AppSurveyResponseService {

  constructor(private http:HttpClient) { }

// firstSurveyResponseSave
  firstSurveyResponseSave(data: FirstSurveyResponseSave){
    return this.http.post<ResponseData>(`${environment.api}/v1/app-encuesta-respuesta/save`, data)
    .pipe(
      map(res=>{
        return res;
      })
    )


  }

  getRecomenpasDcanje(){
    return this.http.post<ResponseData>(`${environment.api}/api/v1/dcanje/get-products`,{})
    .pipe(
      map(res=>{
        return res;
      })
    )

    
  }
}
