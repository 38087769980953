<div class="flex flex-col mx-10 mb-10" [formGroup]="stepForm">

  <!-- Pregunta -->
    <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label  [ngClass]="{'invalid-field': stepForm.get('alimentos')?.invalid && (stepForm.get('alimentos')?.touched || stepForm.get('alimentos')?.dirty)}" >¿Qué alimentos consume regularmente? <br> (Puede elegir más de una opción).</mat-label>
      </div>

        <section class="text-start">
          <div *ngFor="let option of formValues?.AlimentosConsumoRegular; let i = index">
            <mat-checkbox [checked]="stepForm.get('alimentos')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'alimentos')">
              <p class="text-base text-gray-800">{{option}}</p>
            </mat-checkbox>
          </div>
        </section>
    </div>

    <!-- Pregunta -->
    <mat-form-field class="w-full text-start mt-5">
      <mat-label>Describa su estilo de alimentación principal.</mat-label>
      <mat-select formControlName="estilo_alimentacion">
          <mat-option *ngFor="let option of formValues?.EstiloAlimentacion; let i = index" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="stepForm?.get('estilo_alimentacion')?.value == 'Otra: ¿cuál?'">
        <mat-form-field class="w-full">
            <mat-label>¿Cuál?</mat-label>
            <input matInput type="text" placeholder="Chileno" formControlName="otra_estilo_alimentacion">
        </mat-form-field>
    </ng-container>

    <!-- <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label [ngClass]="{'invalid-field': stepForm.get('estilo_alimentacion')?.invalid && (stepForm.get('estilo_alimentacion')?.touched || stepForm.get('estilo_alimentacion')?.dirty)}">Describa su estilo de alimentación principal.</mat-label>
        <mat-radio-group formControlName="estilo_alimentacion">
          <div *ngFor="let option of formValues?.EstiloAlimentacion; let i = index;">
            <mat-radio-button [value]="i.toString()">
              <p class="text-base text-gray-800">{{option}}</p>
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
    </div>

    <ng-container *ngIf="stepForm?.get('estilo_alimentacion')?.value.includes('5') ">
      <mat-form-field class="w-full">
          <mat-label>¿Cuál?</mat-label>
          <input formControlName="otra_estilo_alimentacion" matInput type="text" placeholder="">
      </mat-form-field>
    </ng-container> -->

    <!-- Pregunta -->
    <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label [ngClass]="{'invalid-field': stepForm.get('tipo_bebida')?.invalid && (stepForm.get('tipo_bebida')?.touched || stepForm.get('tipo_bebida')?.dirty)}">  ¿Qué tipo de bebestibles consume regularmente? <br> (Puede elegir más de una opción).</mat-label>
      </div>

        <section class="text-start">
          <div *ngFor="let option of formValues?.BebidasConsumoRegular; let i = index">
            <mat-checkbox [checked]="stepForm.get('tipo_bebida')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'tipo_bebida')">
              <p class="text-base text-gray-800">{{option}}</p>
            </mat-checkbox>
          </div>
        </section>
    </div>

    <ng-container  *ngIf="stepForm?.get('tipo_bebida')?.value.includes('10') " >
      <mat-form-field class="w-full">
          <mat-label>¿Cuál?</mat-label>
          <input matInput type="text" placeholder="" formControlName="otra_tipo_bebida">
      </mat-form-field>
    </ng-container>

    <!-- Pregunta -->
    <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label [ngClass]="{'invalid-field': stepForm.get('compras_habituales')?.invalid && (stepForm.get('compras_habituales')?.touched || stepForm.get('compras_habituales')?.dirty)}">¿Dónde realiza sus compras habituales de alimentos y bebestibles? <br> (Puede elegir más de una opción).</mat-label>
      </div>

        <section class="text-start">
          <div *ngFor="let option of formValues?.LugarComprasHabituales; let i = index">
                <mat-checkbox [checked]="stepForm.get('compras_habituales')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'compras_habituales')">
                  <p class="text-base text-gray-800">{{option}}</p>
                </mat-checkbox>
          </div>
        </section>
    </div>

    <!-- Pregunta -->
    <mat-form-field class="w-full text-start mt-5">
        <mat-label>En general ¿Sus compras habituales generalmente las hace…?</mat-label>
        <mat-select formControlName="realiza_compras">
          <mat-option  *ngFor="let option of formValues?.ComprasHabitualesModalidad; let i = index" [value]="i">
            <p class="text-base text-gray-800">{{option}}</p>
          </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Pregunta -->
    <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label [ngClass]="{'invalid-field': stepForm.get('tipo_vestuario')?.invalid && (stepForm.get('tipo_vestuario')?.touched || stepForm.get('tipo_vestuario')?.dirty)}">¿Cuál es su estilo de vestuario preferido? <br> (Puede elegir más de una opción).</mat-label>
      </div>

        <section class="text-start">
          <div *ngFor="let option of formValues?.EstiloVestuario; let i = index">
            <mat-checkbox [checked]="stepForm.get('tipo_vestuario')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'tipo_vestuario')">
              <p class="text-base text-gray-800">{{option}}</p>
            </mat-checkbox>
          </div>
        </section>
    </div>

    <ng-container *ngIf="stepForm?.get('tipo_vestuario')?.value.includes('3') ">
      <mat-form-field class="w-full">
          <mat-label>¿Cuál?</mat-label>
          <input matInput type="text" placeholder="" formControlName="otra_tipo_vestuario">
      </mat-form-field>
    </ng-container>

    <!-- Pregunta -->
    <mat-form-field class="w-full text-start mt-5">
        <mat-label>¿En qué modalidad realiza su trabajo?</mat-label>
        <mat-select formControlName="modalidad_trabajo">
          <mat-option *ngFor="let option of formValues?.ModalidadTrabajo; let i = index" [value]="i">
            <p class="text-base text-gray-800">{{option}}</p>
          </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Pregunta -->
    <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label  [ngClass]="{'invalid-field': stepForm.get('medio_transporte')?.invalid && (stepForm.get('medio_transporte')?.touched || stepForm.get('medio_transporte')?.dirty)}"> ¿Qué medios de transporte utiliza regularmente? <br> (Puede elegir más de una opción).</mat-label>
      </div>

        <section class="text-start">
          <div *ngFor="let option of formValues?.MediosTransporte; let i = index">
            <mat-checkbox [checked]="stepForm.get('medio_transporte')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'medio_transporte')">
              <p class="text-base text-gray-800">{{option}}</p>
            </mat-checkbox>
          </div>
        </section>
    </div>

    <!-- Pregunta -->
    <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label [ngClass]="{'invalid-field': stepForm.get('hijos')?.invalid && (stepForm.get('hijos')?.touched || stepForm.get('hijos')?.dirty)}">¿Tiene hijos? <br>(Puede elegir más de una opción).</mat-label>
      </div>

        <section class="text-start">
          <div *ngFor="let option of formValues?.Hijos; let i = index">
            <mat-checkbox [disabled]="stepForm.get('hijos')?.value?.includes('0')  && option != 'No tengo'" [checked]="stepForm.get('hijos')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'hijos','0')">
              <p class="text-base text-gray-800">{{option}}</p>
            </mat-checkbox>
          </div>
        </section>
    </div>

    <!-- Pregunta -->
    <mat-form-field class="w-full text-start mt-5">
      <mat-label>¿Está inscrito en algún sistema de salud?</mat-label>
      <mat-select formControlName="sistema_salud">
          <mat-option *ngFor="let option of formValues?.SistemaSalud; let i = index" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="stepForm?.get('sistema_salud')?.value == 'Otro'">
        <mat-form-field class="w-full">
            <mat-label>¿Cuál?</mat-label>
            <input matInput type="text" placeholder="" formControlName="otra_sistema_salud">
        </mat-form-field>
    </ng-container>

    <!-- <div class="w-full text-start mt-5">
        <mat-label>¿Está inscrito en algún sistema de salud?</mat-label>
          <mat-radio-group formControlName="sistema_salud">
            <div *ngFor="let option of formValues?.SistemaSalud; let i = index;">
              <mat-radio-button [value]="i.toString()">
                <p class="text-base text-gray-800">{{option}}</p>
              </mat-radio-button>
            </div>
          </mat-radio-group>
    </div>
    <ng-container  *ngIf="stepForm?.get('sistema_salud')?.value.includes('2') ">
      <mat-form-field class="w-full">
          <mat-label>¿Cuál?</mat-label>
          <input matInput type="text" placeholder="" formControlName="otra_sistema_salud">
      </mat-form-field>
    </ng-container> -->

    <!-- Pregunta -->
    <div class="w-full text-start mt-5">
    <div class="text-base text-gray-800 mb-3 ml-2">
      <mat-label [ngClass]="{'invalid-field': stepForm.get('producto_financiero')?.invalid && (stepForm.get('producto_financiero')?.touched || stepForm.get('producto_financiero')?.dirty)}" >Seleccione los productos financieros que tiene. <br> (Puede elegir más de una opción).</mat-label>
    </div>
    <section>
      <div *ngFor="let option of formValues?.ProductosFinancieros; let i = index">
        <mat-checkbox  [disabled]="stepForm.get('producto_financiero')?.value?.includes('8')  && option !== 'Ninguno'"  [checked]="stepForm.get('producto_financiero')?.value?.includes(i.toString())"   [checked]="stepForm.get('producto_financiero')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'producto_financiero','8')">
          <p class="text-base text-gray-800">{{option}}</p>
        </mat-checkbox>
      </div>
      </section>
  </div>
  <ng-container  *ngIf="stepForm?.get('producto_financiero')?.value.includes('7') ">
    <mat-form-field class="w-full">
        <mat-label>¿Cuál?</mat-label>
        <input matInput type="text" placeholder="" formControlName="otra_producto_financiero">
    </mat-form-field>
  </ng-container>
</div>
