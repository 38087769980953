import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
//services
import { UtilService } from 'src/app/core/services/system/utils.service';
import { GeneralUtils } from 'src/app/shared/utils/general.utils';

@Component({
  template: '',
})
export class BaseComponent {
  @Input() stepForm: FormGroup = new FormGroup({});
  @Input() formValues: any = {};
  @Output() subtitle: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public utilService:UtilService,
    public generalUtils:GeneralUtils
  ){}

  sendSubtitle(title: string): void {
    this.subtitle.emit(title);
  }

  onCheckboxChange(event: any, controlName: string,valueNull: any = null) {
    const value =  event.source.value;
    const control = this.stepForm.get(controlName);
    let currentValue = control?.value || [];
    
    if (event.checked && !currentValue.includes(value)) {
      
      if(value ==valueNull){
        
        control?.setValue([value]);
        return

      }else{
        currentValue.push(value);

      }
    } else if (!event.checked && currentValue.includes(value)) {
      currentValue = currentValue.filter((item: any) => item !== value);
    }
    control?.setValue(currentValue);
  }
}
