

<div class="w-full text-center">

  <!-- Success Exchange Message -->
  <div *ngIf="showMessage" class="flex justify-center items-center h-20 bg-green-500 text-white mb-10 rounded-lg" id="success-message">
    <p class="flex justify-center items-center mb-0 p-4">{{ successExchangeMsg }}</p>
  </div>

  <div class="w-full flex items-center space-x-4 mb-10">
    <mat-icon class="text-blue-950 cursor-pointer" routerLink="/recompensas">arrow_back</mat-icon>
    <p class="panel-nombre text-xl md:text-2xl font-bold text-blue-950">{{product.info.empresa}}</p>
  </div>

    <!-- Contenido -->
      <ng-template #isNotLoading>

        <!-- Contenedor principal -->
        <div class="flex w-full cus-sm:flex-col md:flex-row">

          <!-- Contenedor IZQUIERDA -->
            <div class="w-full w-1/2 flex flex-col">


              <!-- Contenido -->
              <div class="flex flex-col w-10/12 self-center">
                <div class="flex items-center justify-center">
                <img [src]="product.info.imagenGiftcardJPG" alt="Imagen Gift Card" class="max-w-full h-auto items-center panel-imagen">
              </div>


              <p class="text-2xs text-justify px-1 lg:px-8">
                {{ isExpanded ? fullDescription : truncatedDescription }}
                <br>
                <button (click)="toggleDescription()" class="text-blue-950 underline">
                  {{ isExpanded ? 'Ver menos' : 'Ver más' }}
                </button>
              </p>


                <p class="">Selecciona monto a canjear</p>
              <!-- Loop para traer stocks -->
                <div class="flex justify-center gap-2 flex-wrap mb-10">
                    <ng-container class="" *ngFor="let item of product.stocks">
                        <div (click)="funcSelectStock(item)" *ngIf="stockValueSelected == item.valor;else notSelectd" class="rounded-md border border-black p-2 w-36  bg-orange-600">
                            ${{item.valor}}
                        </div>
                        <ng-template #notSelectd>
                            <div class="rounded-md border border-black p-2 cus-sm:w-20 md:w-36 cursor-pointer" (click)="funcSelectStock(item)">
                                ${{item.valor}}
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
              </div>

            </div>

            <!-- Contenedor DERECHA -->
            <div class="text-start w-full w-1/2 flex flex-col p-10 pt-0">
              <!-- Nombre empresa
                <p class="hidden md:block m-0 p-0 company-name text-blue-950">{{product.info.empresa}}</p> -->
              <!-- Título estático -->
                <p class="m-0 p-0 text-color1 font-bold pb-5">Indicaciones para uso</p>
              <!-- Texto condiciones -->


              <div *ngIf="conditionsArray.length > 0">
                <ul>
                  <li *ngFor="let condition of conditionsArray | slice:0:displayLimit; let i = index">
                    {{ i + 1 }}. {{ condition }}
                  </li>
                </ul>

                <button *ngIf="!isShowingAll && conditionsArray.length > displayLimit" (click)="showMoreConditions()" class="text-blue-950 underline">
                  Ver más
                </button>

                <button *ngIf="isShowingAll" (click)="showLessConditions()" class="text-blue-950 underline">
                  Ver menos
                </button>
              </div>




                <!-- Aceptar condiciones -->
                <div class="flex  items-center content-center gap-4 mt-10"  > <input type="checkbox"  [(ngModel)]="isCondicionesGiftcard"> <p class="p-0 m-0 text-xs">Acepto las condiciones de la gift card</p> </div>
                <div class="flex items-center content-center gap-4"> <input type="checkbox"  [(ngModel)]="isTerminosCondiciones"><p class="p-0 m-0 text-xs">Acepto los terminos y condiciones de servicio</p>  </div>
                <!-- Botón canjear -->
                <div  class="mt-10">
                    <button class="bg-[#02A0E1] p-3 w-28 text-white rounded-3xl" (click)="funcCanjear()">Canjear</button>
                </div>
            </div>
          </div>



      </ng-template>

      <!-- Loading Skeleton Screen -->
      <ng-container *ngIf="loading; else isNotLoading">
        <div class="flex w-full animate-pulse">
          <!-- Columna izquierda -->
          <div class="w-2/4 flex flex-col items-center space-y-4">
              <div class="w-8/12 h-1/2 bg-gray-100 rounded-md"></div> <!-- Imagen -->
              <div class="w-1/2 h-6 bg-gray-100 pb-5 rounded-md"></div> <!-- Título -->
              <div *ngFor="let n of [].constructor(3); let i = index" class="w-3/5 h-4 bg-gray-100 rounded-md"></div> <!-- Descripción -->
              <div class="w-32 h-10 bg-gray-100 rounded-md mt-10"></div> <!-- Botón -->
          </div>
          <!-- Columna derecha -->
          <div class="w-2/4 text-start flex flex-col gap-4 p-10 pt-0">
              <div class="w-1/6 h-6 bg-gray-100 rounded-md"></div> <!-- Empresa -->
              <div class="w-2/6 h-6 bg-gray-100 rounded-md"></div> <!-- Título -->
              <div *ngFor="let n of [].constructor(8); let i = index" class="flex gap-4"><div class="w-4 h-4 bg-gray-100 rounded-md"></div><div class="w-3/4 h-4 bg-gray-100 rounded-md"></div></div> <!-- Texto -->
              <div class="w-32 h-10 bg-gray-100 rounded-md mt-2"></div> <!-- Botón de canjear -->
          </div>
      </div>
    </ng-container>

</div>
