import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {HttpClient} from "@angular/common/http"
import { Router } from '@angular/router';

// Environments / Interfaces
import { environment } from 'src/app/environments/environment';
import { ResponseData, SurveyData } from 'src/app/shared/interfaces/services.interface';

@Injectable({
  providedIn: 'root'
})
export class AppSurveyService {

  constructor(private http:HttpClient, private router:Router) { }

  getOne(data: SurveyData){
    return this.http.post<ResponseData>(`${environment.api}/v1/app-encuesta/get-one`, data)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
}
