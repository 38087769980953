<div class="w-full">
  <form [formGroup]="updateForm" (ngSubmit)="onSubmit()">
    <div class="flex flex-col sm:flex-row justify-center gap-6 ">

      <!-- Primera tarjeta -->
      <div class="rounded-xl bg-white shadow-lg p-4 pt-14 md:w-1/2 2xl:w-2/6 text-center">
        <p>Información Personal</p>

        <div class="grid grid-cols-1 ">
          <div class="pt-10">
            <mat-form-field class="w-10/12 lg:w-8/12" appearance="outline">
              <mat-label>Nombre</mat-label>
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="showEditAlert()">edit</mat-icon>

              <input matInput readonly type="text" formControlName="usu_nombre">
            </mat-form-field>
          </div>

          <div>
            <mat-form-field readonly class="w-10/12 lg:w-8/12" appearance="outline">
              <mat-label>Apellido</mat-label>
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="showEditAlert()">edit</mat-icon>

              <input matInput readonly type="text" formControlName="usu_apellido">
            </mat-form-field>
          </div>

          <div>
            <mat-form-field readonly class="w-10/12 lg:w-8/12" appearance="outline">
              <mat-label>Celular</mat-label>
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="showEditAlert()">edit</mat-icon>

              <input matInput readonly type="text" formControlName="usu_fono">
            </mat-form-field>
          </div>

          <!-- <div>
            <mat-form-field readonly class="w-10/12 lg:w-8/12" appearance="outline" >
              <mat-label>Genero</mat-label>
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="showEditAlert()">edit</mat-icon>

              <mat-select  formControlName="sxo_id">
                <mat-option [value]=''>Seleccionar</mat-option>
                <mat-option [value]='0'>Masculino</mat-option>
                <mat-option [value]='1'>Femenino</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->

          <div class="pb-14">
            <mat-form-field readonly class="w-10/12 lg:w-8/12" appearance="outline">
              <mat-label>Correo</mat-label>
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="showEditAlert()">edit</mat-icon>

              <input matInput type="text" formControlName="usu_mail">
            </mat-form-field>
          </div>
        </div>

      </div>

      <!-- Segunda tarjeta -->
      <div class="rounded-xl bg-white shadow-lg p-4 pt-14 md:w-1/2 text-center 2xl:w-2/6">
        <!-- div de arriba: mb-60 md:mb-0 para espaciado mobile footer -->

        <p>Cambiar contraseña</p>

        <div class="grid grid-cols-1 justify-items-center">


          <div class="w-10/12 lg:w-8/12 mt-10">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Actual contraseña</mat-label>
              <!-- <mat-icon matSuffix>lock</mat-icon> -->
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="togglePasswordVisibility()">{{
                hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>


              <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="usu_actual_password">
              <mat-hint>   
                <span *ngIf="updateForm.get('usu_actual_password')?.invalid && updateForm.get('usu_actual_password')?.touched"
                class="text-red-600 text-xs max-w-xs flex-wrap">
            La contraseña debe contener mínimo 6 caracteres y una mayúscula.
          </span>
            </mat-hint>
  
            </mat-form-field>
          
   
          </div>
          <!-- Nueva Password -->
          <div class="w-10/12  lg:w-8/12 mt-10 responsive-margin">

            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Nueva contraseña</mat-label>
              <!-- <mat-icon matSuffix>lock</mat-icon> -->
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="toggleNewPasswordVisibility()">{{
                hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>


              <input matInput [type]="hideNewPassword ? 'password' : 'text'" formControlName="usu_new_password">
              <mat-hint>   
                <span *ngIf="updateForm.get('usu_new_password')?.invalid && updateForm.get('usu_new_password')?.touched"
                class="text-red-600 text-xs max-w-xs flex-wrap">
                  La contraseña debe contener mínimo 6 caracteres y una mayúscula.
                </span>
            </mat-hint>
            </mat-form-field>
         
       


          </div>
          <!-- Repeat Password -->
          <div class="w-10/12  lg:w-8/12 mt-10 responsive-margin">

            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Repetir nueva contraseña</mat-label>
              <!-- <mat-icon matSuffix>lock</mat-icon> -->
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="toggleRepeatedPasswordVisibility()">{{
                hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>


              <input matInput [type]="hideRepeatedPassword ? 'password' : 'text'" formControlName="usu_repeat_password">
              <mat-hint> 
                <span *ngIf="updateForm.get('usu_repeat_password')?.invalid && updateForm.get('usu_repeat_password')?.touched"
                      class="text-red-600 text-xs max-w-xs flex-wrap">
                  La contraseña debe contener mínimo 6 caracteres y una mayúscula.
                </span>
            </mat-hint>
            </mat-form-field>
         


          </div>

          <div class="pt-8 pb-10 mt-2">
            <button class="bg-sky-400 text-white py-2  px-10 rounded-3xl" type="submit">Guardar</button>
          </div>
        </div>

      </div>

    </div>
    <div class="flex flex-col sm:flex-row justify-center gap-6">
      <!-- Primera tarjeta -->
      <div class="md:w-1/2 2xl:w-2/6 text-center"></div>
      <div class="p-2 pt-8 pb-8 md:w-1/2 text-end 2xl:w-2/6">
        <button 
          class="bg-sky-400 text-white py-2 px-10 rounded-3xl cls-delete-cuenta w-full sm:w-auto"
          type="button" 
          (click)="deleteUser()">
          Eliminar cuenta
        </button>
      </div>
    </div>
  </form>

</div>