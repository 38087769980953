import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import {HttpClient} from "@angular/common/http"

// Environments / Interfaces
import { environment } from 'src/app/environments/environment';
import { LoginData, LoginResponse, RegisterData, ResponseData, UpdateUserData, ChangePasswordData } from '../../../shared/interfaces/services.interface';

import { UserLogin } from 'src/app/shared/interfaces/user.interface';
import { AuthService } from '../auth/auth.service';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject: BehaviorSubject<UserLogin>;
  public currentUser: Observable<UserLogin>;

  private pointsUserSubject: BehaviorSubject<number>;
  public currentUserScore: Observable<number>;

  private userStatusSubject = new BehaviorSubject<number | null>(null);

  constructor(private http:HttpClient,private  authService:AuthService) {

    this.currentUserSubject = new BehaviorSubject<UserLogin>(
      JSON.parse(localStorage.getItem('currentUser') as any)
    );

    this.currentUser = this.currentUserSubject.asObservable();

    this.pointsUserSubject = new BehaviorSubject<number>(0);
    this.currentUserScore = this.pointsUserSubject.asObservable();

    const token = this.authService.getToken();
    if (token) {
      this.updateUserStatusFromToken(token);
    }
  }

  public get currentUserValue(): UserLogin {
    return this.currentUserSubject.value;
  }

  private updateUserStatusFromToken(token: string) {
    const tokenDecode: any = jwtDecode(token);
    this.userStatusSubject.next(tokenDecode.data.user_status);
  }


  login(user:LoginData, pTokenCatpcha : string, pSite : string){
    const params = {"usuario" : user.usuario, "password" : user.password, "token": pTokenCatpcha, "site": pSite};
    return this.http.post<LoginResponse|ResponseData>(`${environment.api}/v1/usuario/login`, params)
    .pipe(
      map(res=>{
        if (res.status === 200) {
          res = res as LoginResponse;
          localStorage.setItem('token', res.token);
          localStorage.setItem('currentUser', JSON.stringify(res));
          this.currentUserSubject.next(res);
          this.updateUserStatusFromToken(res.token);
          return res
        }else{
          res = res as ResponseData;
          return res
        }
      })
    )
  }

  register(data: RegisterData){
    return this.http.post<ResponseData>(`${environment.api}/v1/usuario/register`, data)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  getInfo(){
    return this.http.post<ResponseData>(`${environment.api}/v1/usuario/get-info`, {})
    .pipe(
      map(res=>{return res;})
    )
  }

  updateUser(data: UpdateUserData){
    return this.http.post<ResponseData>(`${environment.api}/v1/usuario/update`, data)
    .pipe(
      map(res=>{return res;})
    )
  }

  updatePassword(data: ChangePasswordData){
    return this.http.post<ResponseData>(`${environment.api}/v1/usuario/update-password`, data)
    .pipe(
      map(res=>{return res;})
    )
  }

  getHistoryCanjes(){
    return this.http.post<ResponseData>(`${environment.api}/v1/usuario/get-historial-canje`, {})
    .pipe(
      map(res=>{return res;})
    )
  }

  getHistoryEncuesta(){
    return this.http.post<ResponseData>(`${environment.api}/v1/usuario/get-historial-encuesta`, {})
    .pipe(
      map(res=>{return res;})
    )
  }

  confirmarDatos(data: any){
    return this.http.post<ResponseData>(`${environment.api}/v1/usuario/confirmar-cuenta`, data)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  getHistory(filterTable: {palabra: string, page: number, reg_page:number}){
    return this.http.post<any>(`${environment.api}/v1/historial/get-list`, filterTable)
    .pipe(
      map(res=>{return res;})
    )
  }

  getHistoryTotals(){
    return this.http.post<any>(`${environment.api}/v1/historial/get-history-totals`, {})
    .pipe(
      map(res=>{return res;})
    )
  }

  getCurrentUser(){
    let user = localStorage.getItem('currentUser')
    if(user ==undefined || user == null || user == "" || user == '""'){
      this.authService.logout()
      return {}
    }else{
      //@ts-ignore
      return JSON.parse(user)
    }
  }
  getPoints(){
    return this.http.post<ResponseData>(`${environment.api}/v1/usuario/get-puntos`, {})
    .pipe(
      map(res=>{
        return res;
      })
    )

  }
  updateCurrentUser(user:any){

    let userjson = this.getCurrentUser();

    userjson.firstName = user.usu_nombre;
    userjson.lastName = user.usu_apellido;
    userjson.email = user.usu_mail;
    localStorage.removeItem('currentUser');

    localStorage.setItem('currentUser', JSON.stringify(userjson));
  }

  updatePoints(points:number){
    this.pointsUserSubject.next(points);
  }

  getUserStatus(): Observable<number | null> {
    return this.userStatusSubject.asObservable();
  }
  refreshStatus(token: string) {
    this.authService.refreshToken(token);
    this.updateUserStatusFromToken(token);
  }
  solicitarContrasena(email:string){
    return this.http.post<ResponseData>(`${environment.api}/v1/usuario/solicitar-cambio-contrasena`, {email:email})
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  actualizarContrasena(data:any){
    return this.http.post<ResponseData>(`${environment.api}/v1/usuario/cambiar-contresa`, data)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  deleteUser(){
    return this.http.post<ResponseData>(`${environment.api}/v1/usuario/delete`, {})
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  getGiftCard( idCanje : number | any ){
    return this.http.post<ResponseData>(`${environment.api}/v1/usuario/get-giftcard`, {"id_canje" :  idCanje})
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
  
}
