import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class IpInfoService {
  private readonly API_URL = 'https://ipinfo.io/json?token=e8b4f78478d9f0';

  constructor(private http: HttpClient) { }

  getCountryByIP(): Observable<any> {
    const headers = new HttpHeaders().set('X-Skip-DeleteHeader', 'true');
    return this.http.get<any>(this.API_URL,{headers});
  }
  getIp(): Observable<any> {
    const headers = new HttpHeaders().set('X-Skip-DeleteHeader', 'true');
    return this.http.get<any>('https://api.ipify.org/?format=json',{headers});
  }
}
