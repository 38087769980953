import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DcanjeService } from 'src/app/core/services/system/dcanje.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { Stock, productStockResponse } from 'src/app/shared/interfaces/services.interface';
import { GeneralUtils } from 'src/app/shared/utils/general.utils';
import Swal from 'sweetalert2';
import { DefaultLayoutComponent } from 'src/app/shared/layout/default-layout/default-layout.component';

@Component({
  selector: 'app-reward-id',
  templateUrl: './reward-id.component.html',
  styleUrls: ['./reward-id.component.css']
})
export class RewardIdComponent {
  id: string = '';
  successExchangeMsg: string = '';
  showMessage: boolean = false;
  loading: boolean = true;

  isCondicionesGiftcard: boolean = false;
  isTerminosCondiciones: boolean = false;
  stockValueSelected: number = 0;
  private points: number = 0;
  product: productStockResponse = {
    info: {
      _id: '',
      activo: 0,
      categoria: '',
      condicionesHTML: '',
      condicionesPlana: '',
      descripcion: '',
      empresa: '',
      giftcard: '',
      id: 0,
      imagenGiftcardJPG: '',
      imagenGiftcardPNG: '',
      logoWallet: ''
    },
    stocks: []
  };
  conditionsArray: string[] = [];
  displayLimit: number = 5; 
  isShowingAll: boolean = false; 

  fullDescription: string = ''; 
  truncatedDescription: string = ''; 
  isExpanded: boolean = false; 


  constructor(private route: ActivatedRoute, private userService: UserService, private dcanjeService: DcanjeService, private generalUtils: GeneralUtils, private defaultLayoutComponent: DefaultLayoutComponent) {
    this.id = this.route.snapshot.paramMap.get('id')!;
    this.userService.getPoints().forEach((data) => {
      this.points = data.data

    })

    dcanjeService.getStock({ id_stock: parseInt(this.id) }).subscribe((data) => {
      this.loading = false;
      if (data.status !== 200) {
        return;
      }



      this.product = data.data;

      // Extract and separate the conditions into an array
        if (this.product.info.condicionesPlana) {
          this.conditionsArray = this.product.info.condicionesPlana.split(/(?:\d+\.\s*|\d+-\s*)/).map(condition => condition.trim()).filter(condition => condition.length > 0);
        }

        this.fullDescription = this.product.info.descripcion;
    this.truncatedDescription = this.fullDescription.substring(0, 120);

        


    })




  }
  funcSelectStock(item: Stock) {

    if (item.stock) {
      const stockValue = parseInt(`${item.valor}`.replace(/\./g, ""));
      if (this.points >= stockValue) {
        this.stockValueSelected = item.valor;
      } else {
        this.generalUtils.useSwalt('No tienes suficientes puntos', 'error');
      }

    } else {

    }
  }
  funcCanjear() {
    if (this.stockValueSelected > 0) {
      if (this.isCondicionesGiftcard && this.isTerminosCondiciones) {

        Swal.fire({
          title: "¿Estás seguro de canjear esta gift card por $" + this.stockValueSelected + '?',
          imageUrl: this.product.info.imagenGiftcardJPG,
          showDenyButton: true,
          confirmButtonText: "Sí",
          denyButtonText: `No`,
          showLoaderOnConfirm: true,
          allowOutsideClick:false,
          preConfirm: async (data) => {
            try{
              let res = await this.dcanjeService.getGiftCard({ 'id_giftcard': this.id, 'valor': this.stockValueSelected })

              if(res?.status!=200){
                return Swal.showValidationMessage(`
                ${JSON.stringify(await res?.msg)}
              `);
              }
              const url = res.data.url;
              window.open(url);
              
              this.generalUtils.Toast.fire({icon: 'success', title: res.msg, timer:3000});
              this.defaultLayoutComponent.getUserPoints();

              
              this.showMessage = true;
              this.successExchangeMsg = '¡Tu Gift Card ha sido canjeada con éxito! Si la descarga no se inició automáticamente, revisa tu correo electrónico.';
              
              setTimeout(() => {
                const element = document.getElementById('success-message');
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                }
              }, 100);


              this.stockValueSelected = 0;
              this.isCondicionesGiftcard = false;
              this.isTerminosCondiciones = false;
              return res
            }
            catch(error){
              Swal.showValidationMessage(`
              Request failed: ${error}
            `);
            }
          }
        }).then(r =>{

        })

      } else {
        this.generalUtils.useSwalt('Debes aceptar los terminos y condiciones', 'error')
      }

    } else {
      this.generalUtils.useSwalt('Selecciona un producto', 'error');
    }
  }

  showMoreConditions() {
    this.displayLimit = this.conditionsArray.length;
    this.isShowingAll = true;
  }

  showLessConditions() {
    this.displayLimit = 5;
    this.isShowingAll = false;
  }

  toggleDescription() {
    this.isExpanded = !this.isExpanded;
  }


}
