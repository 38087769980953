import { Component, Input } from '@angular/core';
//services
import { SurveyUserService } from 'src/app/core/services/survey/surveyUser.service';
//interface
import { Survey } from 'src/app/shared/interfaces/survey.interface';
//utils
import { GeneralUtils } from 'src/app/shared/utils/general.utils';

@Component({
  selector: 'app-user-surveys-table',
  templateUrl: './user-surveys-table.component.html',
  styleUrls: ['./user-surveys-table.component.css']
})
export class UserSurveysTableComponent {

  @Input() dataSurveys: Survey[] = []

  columnsToDisplay: string[] = [
    'name',
    'date',
    'score',
    'actions',
  ]

  token:string = ""


  constructor(
    private surveyUserService: SurveyUserService,
    public generalUtils: GeneralUtils,
    ) { }

  ngOnInit(): void {
  }

  goToSurvey(enc_id:number, enc_url_lime:string, eus_token:string){
    const urlLime =`${enc_url_lime}&token=${eus_token}`
    this.surveyUserService.updateSurveyUser(enc_id).subscribe(res=>{
      if(res.status == 200){
        window.open(urlLime, '_blank')
      }else{
        this.generalUtils.Toast.fire({
          icon: 'error',
          title: res.msg,
          timer: 2000
        })
      }
    })
  }
}
