import { Component, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ReCaptchaV3Service } from 'ng-recaptcha';
// services
import { UserService } from 'src/app/core/services/user/user.service';
import { ResponseData, LoginData } from 'src/app/shared/interfaces/services.interface';
import { GeneralUtils } from 'src/app/shared/utils/general.utils';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements AfterViewInit {
  loginForm: FormGroup;
  solicitudCambioForm: FormGroup;
  actualizarPasswordForm: FormGroup;
  emailUpdate: string = '';
  msgSolicitarContrasena: string = '';
  msgActualizarContrasena: string = '';

  loading: boolean = false;
  statusLogin:number =0;

  hidePassword = true;
  hidePasswordRecovery = true;
  hideRepeatedPassword = true;

  // Mobile
  private isMobileSubject = new BehaviorSubject<boolean>(false);
  isMobileSuscribe = this.isMobileSubject.asObservable();
  isMobile: boolean = false;

  // Constructor formulario
  constructor(private fb: FormBuilder
    , private userService: UserService
    , private router: Router
    , private generalUtils: GeneralUtils
    , private recaptchaV3Service: ReCaptchaV3Service
    , private location: Location
  ) {
    this.loginForm = this.fb.group({
      usuario: ['', [Validators.required, Validators.email]],
      password: ['',]
    });
    this.solicitudCambioForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],

    });
    this.actualizarPasswordForm = this.fb.group({
      code: ['', [Validators.required,]],
      contrasena: ['', [Validators.required, Validators.minLength(6), this.generalUtils.containUppercase()]],
      repetirContrasena: ['', [Validators.required, Validators.minLength(6)]]
    }, {
      validator: this.generalUtils.passwordMatchValidator
    });
  }
  
  ngAfterViewInit(): void {
    this.updateScreenSize();
  }

  ngOnInit(): void {
    this.location.subscribe(() => {
      this.cambiarEstado(0);
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const fragment = this.router.parseUrl(this.router.url).fragment;
        if (fragment === 'recuperar-contraseña') {
          this.statusLogin = 1;
        } else if (fragment === 'cambiar-contraseña') {
          this.statusLogin = 2;
        } else {
          this.statusLogin = 0;
        }
      }
    });
  }

  updateScreenSize() {
    const width = window.innerWidth;
    this.isMobileSubject.next(width < 768);
    this.isMobileSuscribe.subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  ejecutarRecaptcha(accion: string) {
    this.recaptchaV3Service.execute(accion).subscribe((token) => {
      this.userLogin(this.loginForm.value, token);
    });
  }
  // Consulta API para iniciar sesión
  userLogin(data: LoginData, pTokenCatpcha: string) {
    const isSite : string = this.isMobile ? 'Web Mobile' : 'Web Desktop'; 
    this.userService.login(data, pTokenCatpcha, isSite).subscribe(res=> {
      this.loading = false;
      if (res.status === 200) {
        this.router.navigate(['/']);
        sessionStorage.setItem('userFromLogin', 'true');
      } else {
        this.loading = false;
        this.generalUtils.Toast.fire({
          icon: 'error',
          title: (res as ResponseData).msg || 'Ups, inconvenientes al iniciar sesión.',
          timer: 2500
        });
      }
    });
  }

  // Enviar formulario
  onSubmit() {
    if (this.loginForm.valid) {
      this.ejecutarRecaptcha('login');
      this.loading = true;
    }
  }
  onSubmitSolicitudCambio() {
    if (this.solicitudCambioForm.valid) {
      this.loading = true;
     this.userService.solicitarContrasena(this.solicitudCambioForm.value.email).subscribe(res=>{
      this.loading = false;
      if(res.status === 200){
        this.cambiarEstado(2);
        this.emailUpdate = this.solicitudCambioForm.value.email;
        this.msgSolicitarContrasena=''
      }
      this.msgSolicitarContrasena = 'hubo un error al enviar el correo';
     })
    }
  }


  onSubmitActualizarPassword() {
    if (this.actualizarPasswordForm.valid) {
      this.loading = true;
      this.userService.actualizarContrasena({...this.actualizarPasswordForm.value,email:this.emailUpdate}).subscribe(res=>{
        this.loading = false;
        if(res.status === 200){
          
          this.generalUtils.Toast.fire({
            icon: 'success',
            title: 'Contraseña actualizada correctamente',
            timer: 2500
          });
          this.cancelarActualizarContrasena();
        }
        else{
          this.msgActualizarContrasena = 'hubo un error al actualizar la contraseña';
        }

      })
     
    }
  }

  cambiarStatusEnCambiarContrasena(){
    this.cambiarEstado(1);
  }
  
  irVerificarCuenta(){
    this.router.navigate(['/register'], { queryParams: { status: '2' } });  
  }

  cambiarActualizarContrasena(){
    this.cambiarEstado(2);
  }

  cambiarEstado(state: number) {
    this.statusLogin = state;
    if (state === 1) {
      this.router.navigate(['/login'], { fragment: 'recuperar-contraseña' });
    } else if (state === 2) {
      this.router.navigate(['/login'], { fragment: 'cambiar-contraseña' });
    } else {
      this.router.navigate(['/login']);
    }
  }

  cancelarActualizarContrasena(){
    this.cambiarEstado(0);
    this.msgActualizarContrasena = '';
    this.msgSolicitarContrasena = '';
    this.emailUpdate = '';
    this.solicitudCambioForm.reset();
    this.actualizarPasswordForm.reset();
    this.loginForm.reset();
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
  togglePasswordVisibilityRecovery() {
    this.hidePasswordRecovery = !this.hidePasswordRecovery;
  }
  toggleRepeatedPasswordVisibility() {
    this.hideRepeatedPassword = !this.hideRepeatedPassword;
  }


}

