import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// librerias
import Swal from 'sweetalert2';
type typesSwalt = 'success' | 'info' | 'warning'| 'error';


@Injectable({
  providedIn: 'root'
})
export class GeneralUtils {

  constructor() { }

   // Swal
   Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });


  async confirmDialog(title: string, text: string, confirmButtonText: string, cancelButtonText: string) {
    return await Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText
    });
  }

  passwordMatchValidator(form: FormGroup) {
    return form.get('contrasena')?.value === form.get('repetirContrasena')?.value
      ? null : { 'mismatch': true };
  }

  useSwalt( pTitle: string,  pIcon: typesSwalt, pTime : number = 1500) {
    Swal.fire({
      icon: pIcon,
      title: pTitle,
      showConfirmButton: false,
      timer: pTime
    });
  }

  calculateUserAge(birthday: Date) {
    const birthDate = birthday;
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age
  }

  containUppercase(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      const hasUppercase = /[A-Z]/.test(value);
      return hasUppercase ? null : { mustContainUppercase: true };
    };
  }
}
