<div class="flex flex-col m-10" [formGroup]="stepForm">

  <!-- Selects datos personales -->
    <mat-form-field class="w-full" color="primary">
        <mat-label>Rut</mat-label>
        <input matInput type="text" formControlName="rut" placeholder="11111111-1" (input)="onInput($event)" (blur)="validateRut()">
    </mat-form-field>
    <!-- <mat-form-field class="w-full">
        <mat-label>Telefono</mat-label>
        <input matInput type="number" formControlName="telefono">
    </mat-form-field> -->
    <!-- {{formValues?.Genero | json}} -->
    <mat-form-field class="w-full">
        <mat-label>Género</mat-label>
        <mat-select formControlName="genero">
            <mat-option *ngFor="let option of formValues?.Genero; let i = index" [value]="option.sxo_codigo">{{option.sxo_nombre}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Fecha de nacimiento</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="fecha_nacimiento" [min]="minDate" [max]="maxDate">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <!-- <mat-form-field class="w-full">
        <mat-label>País</mat-label>
        <mat-select formControlName="pais">
          <mat-option *ngFor="let option of formValues?.Pais; let i = index" [value]="option.pas_id">{{option.pas_nombre}}</mat-option>
        </mat-select>
    </mat-form-field> -->
    <!-- <ng-container *ngIf="stepForm?.get('pais')?.value == '2'">
      <mat-form-field class="w-full">
          <mat-label>Otro pais</mat-label>
          <input formControlName="otra_pais" matInput type="text" placeholder="Chileno">
      </mat-form-field>
  </ng-container> -->
    <mat-form-field class="w-full">
        <mat-label>Región</mat-label>
        <mat-select formControlName="region">
          <mat-option *ngFor="let option of formValues?.Region; let i = index" [value]="option.rgn_id">{{option.rgn_nombre}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="stepForm.get('region')?.value" class="w-full">
        <mat-label>Comuna</mat-label>
        <mat-select formControlName="comuna">
          <mat-option *ngFor="let option of filterComunas()" [value]="option.cmn_id">{{option.cmn_nombre}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Nacionalidad</mat-label>
      <mat-select formControlName="nacionalidad">
          <mat-option *ngFor="let option of formValues?.Nacionalidad; let i = index" [value]="option">{{option}}</mat-option>
      </mat-select>
  </mat-form-field>

  <ng-container *ngIf="stepForm?.get('nacionalidad')?.value == 'Otra: ¿cuál?'">
      <mat-form-field class="w-full">
          <mat-label>Otra Nacionalidad</mat-label>
          <input matInput type="text" placeholder="Chileno" formControlName="otra_nacionalidad">
      </mat-form-field>
  </ng-container>

  <div class="w-full mb-6">
    <div class="flex items-center">
      <mat-form-field class="w-1/4 md:w-1/5 mr-2" style="min-width: 70px !important;">
        <input matInput formControlName="usu_cod_area" value="+56" readonly>
      </mat-form-field>
  
      <mat-form-field class="w-full">
        <mat-label>Teléfono</mat-label>
        <input matInput type="text" formControlName="usu_fono" placeholder="Teléfono" id="usu_fono" maxlength="9" (input)="onPhoneNumberInput($event)">
        <mat-error *ngIf="stepForm.get('usu_fono')?.errors?.['required']">
          El teléfono es obligatorio.
        </mat-error>
        <mat-error *ngIf="stepForm.get('usu_fono')?.errors?.['pattern']">
          El teléfono solo debe contener números.
        </mat-error>
        <mat-error *ngIf="stepForm.get('usu_fono')?.errors?.['minlength']">
          El teléfono debe tener al menos 9 dígitos.
        </mat-error>
      </mat-form-field>
    </div>
    <!-- <p style="color: gray;">Por favor, registre su teléfono celular. Esta información será usada solo con fines de clasificación y protegida según nuestra política de privacidad.</p> -->
  </div>
  
  <!-- <div class="w-full mb-6">
    <div class="flex">
      <input formControlName="usu_cod_area" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:border-transparent w-1/4 md:w-1/5 mr-2"
      id="usu_cod_area" type="text" value="+56" readonly>

      <input formControlName="usu_fono" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"id="usu_fono" type="text" maxlength="9" placeholder="Teléfono" (input)="onPhoneNumberInput($event)">
    </div>
    <div *ngIf="stepForm.get('usu_fono')?.invalid && stepForm.get('usu_fono')?.touched" class="text-red-600 text-xs absolute">
      <div *ngIf="stepForm.get('usu_fono')?.errors?.['required']">
        El teléfono es obligatorio.
      </div>
      <div *ngIf="stepForm.get('usu_fono')?.errors?.['pattern']">
        El teléfono solo debe contener números.
      </div>
      <div *ngIf="stepForm.get('usu_fono')?.errors?.['minlength']">
        El teléfono debe tener al menos 9 dígitos.
      </div>
    </div>
  </div>  -->
    <!-- <mat-form-field class="w-full">
        <mat-label>Nivel Socioeconómico</mat-label>
        <input matInput type="number" formControlName="nivel_socioeconomico">
    </mat-form-field> -->
    <mat-form-field class="w-full">
      <mat-label>¿Es usted la persona que aporta el ingreso principal del hogar?</mat-label>
      <mat-select formControlName="aporte_hogar">
        <mat-option *ngFor="let option of formValues?.NivelSocioeconomico?.aporte_hogar	; let i = index" [value]="option.aph_id	">{{option.aph_nombre}}</mat-option>
      </mat-select>
  </mat-form-field>
    <mat-form-field class="w-full">
    <mat-label> {{stepForm?.get('aporte_hogar')?.value != '2'?'¿Cuál es su actividad principal?':'¿Cuál es la actividad principal del sostenedor del hogar?'}}</mat-label>
    <mat-select formControlName="actividad">
      <mat-option *ngFor="let option of formValues?.NivelSocioeconomico?.actividad; let i = index" [value]="option.act_id	">{{option.act_nombre}}</mat-option>
    </mat-select>
</mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{stepForm?.get('aporte_hogar')?.value != '2'?'¿Cuál es su nivel educacional?':'¿Cuál es el nivel educacional del sostenedor del hogar?'}}</mat-label>
      <mat-select formControlName="nivel_educacion">
        <mat-option *ngFor="let option of formValues?.NivelSocioeconomico?.nivel_educacion	; let i = index" [value]="option.ned_id">{{option.ned_nombre}}</mat-option>
      </mat-select>
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>{{stepForm?.get('aporte_hogar')?.value != '2'?'¿Cuál es su trabajo o ocupación?':'¿Cuál es el trabajo u ocupación del sostenedor del hogar?'}} </mat-label>
    <mat-select formControlName="ocupacion">
      <mat-option *ngFor="let option of formValues?.NivelSocioeconomico?.ocupacion; let i = index" [value]="option.ocp_id" class="text-sm">{{option.ocp_nombre	}}</mat-option>
    </mat-select>
</mat-form-field>


    <!--Pregunta Partido-->
    <!-- <mat-form-field class="w-full">
      <mat-label>En términos políticos, las personas se pueden sentir más cercanas o lejanas a la izquierda o a la derecha, ¿Usted con cuál de las siguientes posiciones se siente más cercano...?</mat-label>
      <mat-select formControlName="partido_politico">
          <mat-option *ngFor="let option of formValues?.PartidoPolitico; let i = index" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <!-- <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <label
          [ngClass]="{'invalid-field': stepForm.get('partido_politico')?.invalid && (stepForm.get('partido_politico')?.touched || stepForm.get('partido_politico')?.dirty)}">
          En términos políticos, las personas se pueden sentir más cercanas o lejanas a la izquierda o a la derecha, ¿Usted con cuál de las siguientes posiciones se siente más cercano...?
        </label>
      </div>
      <section>
        <mat-radio-group formControlName="partido_politico">
          <div *ngFor="let option of formValues?.PartidoPolitico; let i = index;">
            <mat-radio-button [value]="i.toString()">
              <p class="text-base text-gray-800">{{option}}</p>
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </section>
    </div> -->

    <!-- Pregunta 1 -->
    <!-- <div class="w-full text-start mt-5" >
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label   [ngClass]="{'invalid-field': stepForm.get('recibir_encuestas')?.invalid && (stepForm.get('recibir_encuestas')?.touched || stepForm.get('recibir_encuestas')?.dirty)}" >¿Cómo prefiere recibir nuestras encuestas? <br> (Puede elegir más de una opción).</mat-label>
      </div>
      <section>
        <div *ngFor="let option of formValues?.PreferenciaEncuestas; let i = index;">
          <mat-checkbox [checked]="stepForm.get('recibir_encuestas')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'recibir_encuestas')">
            <p class="text-base text-gray-800">{{option}}</p>
          </mat-checkbox>
        </div>
      </section>
    </div> -->


    <!-- Pregunta 2 -->
    <!-- <div class="w-full text-start mt-5 ">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label   [ngClass]="{'invalid-field': stepForm.get('tipos_encuestas')?.invalid && (stepForm.get('tipos_encuestas')?.touched || stepForm.get('tipos_encuestas')?.dirty)}">Seleccione los tipos de encuestas que más le interesa responder. (Puede elegir más de una opción).</mat-label>
      </div>

      <section>
        <div *ngFor="let option of formValues?.InteresEncuestas; let i = index;">
          <mat-checkbox [checked]="stepForm.get('tipos_encuestas')?.value?.includes(i.toString())"  [value]="i.toString()" (change)="onCheckboxChange($event, 'tipos_encuestas')">
            <p class="text-base text-gray-800">{{option}}</p>
          </mat-checkbox>
        </div>
      </section>
    </div> -->
</div>
