<div class="w-full">
  <form [formGroup]="updateForm" (ngSubmit)="onSubmit()">
    <div class="flex flex-col sm:flex-row justify-center gap-6 ">

      <!-- Primera tarjeta -->
      <div class="rounded-xl bg-white shadow-lg p-4 pt-14 md:w-1/2 2xl:w-2/6 text-center">
        <p>Información Personal</p>

        <div class="grid grid-cols-1 ">
          <div class="pt-10">
            <mat-form-field class="w-10/12 lg:w-8/12" appearance="outline">
              <mat-label>Nombre</mat-label>
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="showEditAlert()">edit</mat-icon>

              <input matInput readonly type="text" formControlName="usu_nombre">
            </mat-form-field>
          </div>

          <div>
            <mat-form-field readonly class="w-10/12 lg:w-8/12" appearance="outline">
              <mat-label>Apellido</mat-label>
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="showEditAlert()">edit</mat-icon>

              <input matInput readonly type="text" formControlName="usu_apellido">
            </mat-form-field>
          </div>

          <div>
            <mat-form-field readonly class="w-10/12 lg:w-8/12" appearance="outline">
              <mat-label>Celular</mat-label>
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="showEditAlert()">edit</mat-icon>

              <input matInput readonly type="text" formControlName="usu_fono">
            </mat-form-field>
          </div>

          <!-- <div>
            <mat-form-field readonly class="w-10/12 lg:w-8/12" appearance="outline" >
              <mat-label>Genero</mat-label>
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="showEditAlert()">edit</mat-icon>

              <mat-select  formControlName="sxo_id">
                <mat-option [value]=''>Seleccionar</mat-option>
                <mat-option [value]='0'>Masculino</mat-option>
                <mat-option [value]='1'>Femenino</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->

          <div class="pb-14">
            <mat-form-field readonly class="w-10/12 lg:w-8/12" appearance="outline">
              <mat-label>Correo</mat-label>
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="showEditAlert()">edit</mat-icon>

              <input matInput type="text" formControlName="usu_mail">
            </mat-form-field>
          </div>
        </div>

      </div>

      <!-- Segunda tarjeta -->
      <div class="rounded-xl bg-white shadow-lg p-4 pt-14 md:w-1/2 text-center 2xl:w-2/6">
        <!-- div de arriba: mb-60 md:mb-0 para espaciado mobile footer -->

        <p>Cambiar contraseña</p>

        <div class="grid grid-cols-1 justify-items-center">


          <div class="w-10/12 lg:w-8/12 mt-10">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Actual contraseña</mat-label>
              <!-- <mat-icon matSuffix>lock</mat-icon> -->
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="togglePasswordVisibility()">{{
                hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>


              <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="usu_actual_password">
              <mat-hint>   
                <span *ngIf="updateForm.get('usu_actual_password')?.invalid && updateForm.get('usu_actual_password')?.touched"
                class="text-red-600 text-xs max-w-xs flex-wrap">
            La contraseña debe contener mínimo 6 caracteres y una mayúscula.
          </span>
            </mat-hint>
  
            </mat-form-field>
          
   
          </div>
          <!-- Nueva Password -->
          <div class="w-10/12  lg:w-8/12 mt-10 responsive-margin">

            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Nueva contraseña</mat-label>
              <!-- <mat-icon matSuffix>lock</mat-icon> -->
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="toggleNewPasswordVisibility()">{{
                hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>


              <input matInput [type]="hideNewPassword ? 'password' : 'text'" formControlName="usu_new_password">
              <mat-hint>   
                <span *ngIf="updateForm.get('usu_new_password')?.invalid && updateForm.get('usu_new_password')?.touched"
                class="text-red-600 text-xs max-w-xs flex-wrap">
                  La contraseña debe contener mínimo 6 caracteres y una mayúscula.
                </span>
            </mat-hint>
            </mat-form-field>
         
       


          </div>
          <!-- Repeat Password -->
          <div class="w-10/12  lg:w-8/12 mt-10 responsive-margin">

            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Repetir nueva contraseña</mat-label>
              <!-- <mat-icon matSuffix>lock</mat-icon> -->
              <mat-icon matSuffix class="text-gray-400 cursor-pointer" (click)="toggleRepeatedPasswordVisibility()">{{
                hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>


              <input matInput [type]="hideRepeatedPassword ? 'password' : 'text'" formControlName="usu_repeat_password">
              <mat-hint> 
                <span *ngIf="updateForm.get('usu_repeat_password')?.invalid && updateForm.get('usu_repeat_password')?.touched"
                      class="text-red-600 text-xs max-w-xs flex-wrap">
                  La contraseña debe contener mínimo 6 caracteres y una mayúscula.
                </span>
            </mat-hint>
            </mat-form-field>
         


          </div>

          <div class="pt-8 pb-10 mt-2">
            <button class="bg-sky-400 text-white py-2  px-10 rounded-3xl" type="submit">Guardar</button>
          </div>
        </div>

      </div>




    </div>

  </form>

  <!-- <div class="w-1/2">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <h1>Actualización de datos</h1>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="updateForm">
            <mat-form-field class="w-full">
              <mat-label>Nombre</mat-label>
              <input matInput  type="text" formControlName="usu_nombre">
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Apellido</mat-label>
              <input matInput type="text" formControlName="usu_apellido">
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Rut</mat-label>
              <input matInput type="text" (input)="onInput($event)" formControlName="usu_rut">
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Correo</mat-label>
              <input matInput type="text" formControlName="usu_mail">
            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Telefono</mat-label>
              <input matInput type="number" formControlName="usu_fono">
            </mat-form-field>


            <div class="flex justify-between space-x-4">
              <mat-form-field class="w-full">
                <mat-label>Genero</mat-label>
                <mat-select formControlName="sxo_id">
                  <mat-option [value]=''>Seleccionar</mat-option>
                  <mat-option [value]='0'>Opcion 0</mat-option>
                  <mat-option [value]='1'>Masculino</mat-option>
                  <mat-option [value]='2'>Femenino</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="w-full">
                <mat-label>Fecha Nacimiento</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="usu_fecha_nacimiento">
                <mat-datepicker-toggle matIconSuffix [for]="picker" ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>


            <div class="mt-5 mb-5">
              <h4>Cambiar Contraseña</h4>
              <hr>
              <mat-form-field class="w-full">
                <mat-label>Nueva Contraseña</mat-label>
                <input matInput type="text" formControlName="usu_password">
              </mat-form-field>

              <mat-form-field class="w-full">
                <mat-label>Repetir Contraseña</mat-label>
                <input matInput type="text"  formControlName="usu_repeat_password">
              </mat-form-field>
            </div>
            <div class="flex justify-end">
              <button mat-raised-button color="primary" (click)="onSubmit()" type="submit">Guardar</button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div> -->
</div>