<div class="flex flex-col h-full">
  <div>
    <nav class="bg-white p-4 flex justify-between items-center">
      <div class="text-white text-lg font-bold">
        <img src="assets/images/logo_cadem_online.png" alt="Logo" class="h-16">
      </div>

      <div class="flex items-center space-x-4">

      <!-- Overlay para cerrar el menú de notificaciones al hacer clic fuera -->
      <div *ngIf="isAlertMenuOpen" class="fixed inset-0 bg-black opacity-30 transition-opacity duration-500 z-50 cursor-pointer" (click)="closeNotificationMenu()"></div>

         <!-- Boton Notificacion -->
         <div (click)="openDialog()"  class="cursor-pointer p-2 flex justify-center items-center rounded-full">
          <img class="h-full icon-size" src="../../../../assets/images/ayuda.png" alt="">
        </div>
         <div  class="cursor-pointer p-2 flex justify-center items-center rounded-full" >
          <span *ngIf="hasUnreadAlerts === true" class="badge"></span>
          <img src="../../../../assets/images/notificacion.png" aria-hidden="false" class="h-full icon-size" alt="sin_notificacion" (click)="toggleNotificationsMenu()">
        </div>

        <!-- Menú notificaciones -->
        <!-- Contenedor de notificaciones -->
        <div *ngIf="isAlertMenuOpen" class="absolute top-16 right-10 bg-white shadow-lg rounded-xl w-80 p-6 z-50">
          <h4 class="text-center">Notificaciones</h4>
          <div *ngIf="isLoading == false">
            <div *ngFor="let alert of alerts">
              <div class="rounded-xl hover:bg-[#cecece2f] cursor-pointer px-2 py-1 mb-4 menu-shadow">
                <div class="p-2" [ngClass]="{'text-gray-400': alert.e_alt_id === 2}" (click)="sidebarNavigation('/encuestas')">
                  <div class="flex justify-start gap-2" matTooltip="{{ alert.alt_nombre }}">
                    <!-- Icon -->
                    <div [ngClass]="{'bg-[#c0bfbfc0]': alert.e_alt_id === 2}" class="bg-[#5BBFEB] w-[30px] h-[30px] flex items-center justify-center rounded-full text-center flex-shrink-0">
                      <mat-icon class="text-white text-base">fact_check</mat-icon>
                    </div>
                    <!-- Name -->
                    <div class="flex flex-grow items-center truncate">
                      <span class="truncate">{{ alert.alt_nombre }}</span>
                    </div>

                    <!-- Time -->
                    <small class="text-gray-400 truncate flex-shrink-0">{{ alert.timeElapsed }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Skeleton loader para alertas -->
          <div *ngIf="isLoading">
            <div class="skeleton" style="height: 42px; width: 100%;"></div>
            <div class="skeleton" style="height: 42px; width: 100%;"></div>
            <div class="skeleton" style="height: 42px; width: 100%;"></div>
            <div class="skeleton" style="height: 42px; width: 100%;"></div>
          </div>
      </div>

        <!-- Botón de Logout -->
        <div (click)="userLogout()" class="cursor-pointer p-2 hover:bg-slate-200 flex justify-center items-center rounded-full">
          <img src="../../../../assets/images/logout.png" aria-hidden="false" class="h-full icon-size" alt="salir">
        </div>

      </div>
    </nav>

  <!-- Banda header -->
  <div class="h-12 bg-gradient-to-t from-violet-400 to-cyan-500 relative flex justify-end px-4">
      <!-- Botón hamburguesa -->
      <button id="hamburger-button" class="focus:outline-none block md:hidden absolute" (click)="toggleSidebar()">
        <mat-icon class="text-white">menu</mat-icon>
      </button>

      <div class="flex justify-end items-center">
        <!-- Puntos -->
        <div id="puntos" class="bg-white shadow-md p-2 px-6 rounded-full flex justify-center items-center h-10">
          <p *ngIf="!isLoading" class="text-base m-0 font-medium whitespace-nowrap">Tienes <span class="text-[#EC3480] font-bold">{{user.userPoints}} puntos</span></p>

        <!-- Refresh -->
        <div *ngIf="!isLoading" class="flex items-center relative left-2">
          <i class="material-icons cursor-pointer text-color4 hover:text-color4-h px-2" (click)="getUserPoints()">refresh</i>
        </div>

          <!-- Point Spinner -->
          <div *ngIf="isLoading" class="w-32 flex items-center">
            <mat-spinner class="mx-auto" [diameter]="25" [strokeWidth]="3"></mat-spinner>
          </div>
        </div>

      </div>

    </div>
  </div>

  <!-- Overlay para cerrar el sidebar al hacer clic fuera -->
  <div
    *ngIf="isOpen"
    class="fixed inset-0 bg-black opacity-50 z-30"
    (click)="toggleSidebar()">
  </div>

  <!-- Sidebar -->
  <div class="h-full flex flex-grow tems-stretch relative" >
    <div [ngClass]="{'hidden md:block': !isOpen, 'block  w-1/6 absolute': isOpen}" class="bg-white p-4 sidebar" style="width: 350px; min-width: 250px; z-index: 40; height: 100vh;">


      <!-- Foto de perfil -->
      <div class="flex items-center mb-5 flex-col mt-1 justify-center">
        <div *ngIf="imageBase64"
        style="top: -60px; background-image: url('data:image/jpeg;base64,{{imageBase64}}');"
        class="bg-blue-200 rounded-full h-28 w-28 flex items-center justify-center text-6xl text-white font-black avatar-font bg-cover bg-center cursor-pointer group"
        (click)="triggerFileInput()">
            <ng-container  *ngIf="isLoadingFotoService; else isNotLoadingService">
              <div class="w-32 flex items-center">
                <mat-spinner class="mx-auto spinner-foto" [diameter]="25" [strokeWidth]="3"></mat-spinner>
              </div>
            </ng-container>
            <ng-template #isNotLoadingService>
              <div class="relative flex items-center justify-center h-full w-full">
              <!-- <div class="absolute inset-0 bg-color1 bg-opacity-50 rounded-full flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-10">
                <mat-icon class="flex items-center justify-center text-white text-3xl w-full">photo_camera</mat-icon>
              </div> -->
            </div>
            </ng-template>
          </div>

          <div *ngIf="!imageBase64"
            style="top: -60px;"
            class="bg-blue-200 rounded-full h-28 w-28 flex items-center justify-center text-6xl text-white font-black avatar-font cursor-pointer group"
            (click)="triggerFileInput()">

            <ng-container  *ngIf="isLoadingFotoService; else isNotLoadingService">
              <div class="w-32 flex items-center">
                <mat-spinner class="mx-auto spinner-foto" [diameter]="25" [strokeWidth]="3"></mat-spinner>
              </div>
            </ng-container>

            <ng-template #isNotLoadingService>
              <div class="relative flex items-center justify-center h-full w-full">
                <span class="avatar-font">{{user.firstName.charAt(0) + user.lastName.charAt(0) | uppercase}}</span>
                <!-- <div class="absolute inset-0 bg-color1 bg-opacity-50 rounded-full flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-10">
                  <mat-icon class="flex items-center justify-center text-white text-3xl w-full">photo_camera</mat-icon>
                </div> -->
              </div>
            </ng-template>

          </div>
          <!-- <input type="file" (change)="onFileSelected($event)" #fileInput style="display: none;" /> -->

          <!-- Nombre usuario -->
          <div class="text-center mt-5">
            <p class="font-semibold new-font-name truncate">{{user.firstName}}</p>
          </div>

      </div>
      <nav>

      <!-- Menú de navegación -->
        <ul class="space-y-4">
          <li class="p-2 rounded flex  " routerLinkActive="routerActive" [routerLinkActiveOptions]="{exact: true}" (click)="onMobileSidebarToggle()">
            <a routerLink="" class="flex items-end gap-2 text-blue-950"> <img class="icon-size" src="../../../../assets/images/home.png">
              Home</a>
          </li>
          <li class="p-2 rounded" routerLink="/encuestas" routerLinkActive="routerActive" [routerLinkActiveOptions]="{exact: true}" (click)="onMobileSidebarToggle()">
            <a routerLink="/encuestas" class="flex items-end gap-2 text-blue-950"> <img class="icon-size" src="../../../../assets/images/encuestas.png">
              Encuestas</a>

          </li>

          <li class="p-2 rounded" routerLink="/recompensas" routerLinkActive="routerActive" [routerLinkActiveOptions]="{exact: true}" (click)="onMobileSidebarToggle()">
            <a routerLink="/recompensas" class="flex items-end gap-2 text-blue-950"> <img class="icon-size" src="../../../../assets/images/recompensas.png">
              Recompensas </a>
          </li>
          <li class="p-2 rounded" routerLink="/usuario/actualizar" routerLinkActive="routerActive"
            [routerLinkActiveOptions]="{exact: true}" (click)="onMobileSidebarToggle()">
            <a routerLink="/usuario/actualizar" class="flex items-end gap-2 text-blue-950"> <img class="icon-size" src="../../../../assets/images/cuenta.png">
              Mis datos</a>
          </li>
          <li class="p-2 rounded" routerLink="/historial" routerLinkActive="routerActive" [routerLinkActiveOptions]="{exact: true}" (click)="onMobileSidebarToggle()">
            <a routerLink="/historial" class="flex items-end gap-2 text-blue-950"> <img class="icon-size" src="../../../../assets/images/historial.png">
              Historial</a>
          </li>
        </ul>
      </nav>
    </div>

    <div class="w-full px-8 py-8 md:py-16 router-outlet-background" [ngClass]="{'overflow-hidden': isOpen}">
      <router-outlet></router-outlet>
    </div>
  </div>

  <!-- Footer Popup (mobile) -->
    <div *ngIf="!isFooterClosed" class="fixed inset-x-0 bottom-0 bg-[#0C284C] p-4 text-white flex flex-col justify-center items-center md:hidden">
      <div class="flex justify-between p-2 w-full">
        <h2 class="flex text-base font-bold text-white items-center mb-0 pl-2">Descarga nuestra app</h2>
        <button (click)="closePopUpFooter()" class="text-2xl font-bold">✕</button>
      </div>
      <div class="flex space-x-8 justify-center p-5 mt-4">
        <a href="https://apps.apple.com/cl/app/cadem-online/id6733242366?l=en-GB" target="_blank" class=" w-[50%] max-w-[150px]">
          <img src="assets/images/app_store_temp_es.png" alt="App Store" >
        </a>

        <img src="assets/images/google_play_temp_es.png" alt="Play Store" class=" w-[50%] max-w-[150px]">
      </div>
    </div>

</div>
