import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {HttpClient} from "@angular/common/http"
import { Router } from '@angular/router';

// Environments / Interfaces
import { environment } from 'src/app/environments/environment';
import { ResponseData } from 'src/app/shared/interfaces/services.interface';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(private http:HttpClient, private router:Router) { }

  getAll(){
    return this.http.post<ResponseData>(`${environment.api}/v1/encuesta/get-all`, {})
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
  

  getByUser(){
    return this.http.post<ResponseData>(`${environment.api}/v1/encuesta/get-by-user`, {})
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
}
