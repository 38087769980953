

<div class="flex flex-col justify items-center">
    <p class="title font-semibold text-center pt-3">Canjea tus puntos entre cientos de Gift Cards y productos de grandes marcas.</p>
    <p class="subtitle text-neutral-500 text-xs pt-0 font-semibold text-center -mt-5">1 punto equivale a $1. Es decir, si tienes 500 puntos acumulados significa que tienes $500 de saldo.</p>

        <mat-form-field class="w-full ">
          <mat-label>Todas las categorias</mat-label>
          <select matNativeControl   (change)="onCategoryChange($event)">
            <option value="todos" selected>Todos</option>
            <option *ngFor="let item of listCategory" [value]="item">{{item}}</option>
          </select>
        </mat-form-field>

        <div class="flex flex-wrap gap-12 flex-col-2">
          <ng-template #isNotLoading>
            <div class="w-full" *ngFor="let item of listProductFilter">
              <div class="w-full flex items-center mb-2 gap-4">
                <span class="bg-color1 text-color3 p-1 rounded-md m-0">
                  <mat-icon class="p-0 m-0" aria-hidden="false" aria-label="Example home icon" [fontIcon]="getIconCategory(item.categorias)"></mat-icon>
                </span>
                <p class="categorias font-semibold cus-sm:text-sm md:text-base p-0 m-0">{{item.categorias}}</p>
              </div>

              <div class="panel-box flex gap-5 flex-wrap ">
                <div (click)="irRuta(item)" *ngFor="let item of item.list"
                     class="cus-sm:h-24 cus-sm:w-32 md:h-64 md:w-72 shadow-md flex rounded-xl justify-center items-center cursor-pointer bg-white">
                  <img [src]="item.imagenGiftcardJPG" alt="Gift Card" class="">
                </div>
              </div>

            </div>
          </ng-template>

          <!-- Skeleton Screen Loading -->
          <ng-container  *ngIf="isLoadingReward; else isNotLoading">
            <div class="w-full flex flex-wrap justify-center md:justify-start gap-4 mt-14 pl-4">
              <div *ngFor="let n of [].constructor(16); let i = index"
                class="cus-sm:h-24 cus-sm:w-32 w-72 h-52 bg-gray-100 rounded-md animate-pulse">
              </div>
            </div>
          </ng-container>

        </div>

</div>
