import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import Swal from 'sweetalert2';
import  { jwtDecode } from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService:AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = this.authService.getToken();
        if (token) {
            const tokenDecode: any = jwtDecode(token);
            const fechaActualUnix = Date.now();
            if((fechaActualUnix / 1000) > tokenDecode.exp){
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops... :c',
                    text: 'Sesión caducada. Ingrese sesión de nuevo.'
                })
                this.router.navigate(['/login']);
                return false;
            }else{
                
            }
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
