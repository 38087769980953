import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

//components
import { FirstSurveyComponent } from './containers/first-survey/first-survey.component';
import { BasicDataComponent } from './containers/first-survey/components/basic-data/basic-data.component';
import { FreeTimeComponent } from './containers/first-survey/components/free-time/free-time.component';
import { LifestyleComponent } from './containers/first-survey/components/lifestyle/lifestyle.component';
import { TechCommComponent } from './containers/first-survey/components/tech-comm/tech-comm.component';

//material modules
import { MaterialModule } from './material.module';
import { SurveysComponent } from './surveys.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';



@NgModule({
  declarations: [
    FirstSurveyComponent,
    BasicDataComponent,
    FreeTimeComponent,
    LifestyleComponent,
    TechCommComponent,
    SurveysComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MaterialModule,
    MatTableModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDatepickerModule
  ],
  providers:[{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' },],
  exports:[]
  
})
export class SurveysModule { }
