import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
//material
import { MaterialModule } from './app-material.module';
// components
import { AppComponent } from './app.component';
import { LoginComponent } from './public/login/login.component';
import { RegisterComponent } from './public/register/register.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { RedirectToLimeSurveyComponent } from './public/redirect-to-limesurvey/redirect-to-limesurvey.component';
//-home
import { HomeComponent } from './private/home/home.component';
import { UserSurveysTableComponent } from './private/home/components/user-surveys-table/user-surveys-table.component';
import { FirstSurveyAlertModalComponent } from './private/home/components/first-survey-alert-modal/first-survey-alert-modal.component';

import { HistoryComponent } from './private/history/history.component';
import { RewardsComponent } from './private/rewards/rewards.component';
import { SurveyComponent } from './private/survey/survey.component';
import { LayoutLoginComponent } from './shared/layout/login-layout/layout-login.component';
import { ModalAyudaComponent } from './shared/components/modal-ayuda/modal-ayuda.component';
import { DefaultLayoutComponent } from './shared/layout/default-layout/default-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//-user
import { UserComponent } from './private/user/user.component';
import { UserUpdateComponent } from './private/user/user-update/user-update.component';

// modules
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule ,FormsModule} from '@angular/forms';
import { SurveysModule } from './private/surveys/surveys.module';
import { JwtInterceptor } from './core/interceptor/jwt.interceptor';
import { ErrorInterceptor } from './core/interceptor/error.interceptor';

// angular material
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RewardIdComponent } from './private/rewards/reward-id/reward-id.component';
import { NgxSpinnerModule } from "ngx-spinner";
import {MatDialogModule} from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RewardIdComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    RedirectToLimeSurveyComponent,
    ModalAyudaComponent,

    //home
    HomeComponent,
    UserSurveysTableComponent,
    FirstSurveyAlertModalComponent,
    HistoryComponent,
    RewardsComponent,

    SurveyComponent,
    //user
    UserComponent,
    UserUpdateComponent,
    //layouts
    LayoutLoginComponent,
    DefaultLayoutComponent

  ],
  exports:[
    MaterialModule
  ],
  imports: [
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SurveysModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    FormsModule,
    NgxSpinnerModule.forRoot({type: 'ball-scale-multiple'}),
    RecaptchaV3Module,
    MatTooltipModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LfoFyAqAAAAAAXv3I_kr3Z5GvwPmvq7O--vDz97" }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
