
  <!-- <app-user-surveys-table [dataSurveys]="dataSurveys"></app-user-surveys-table> -->

  <div class="flex gap-2 flex-wrap 2xl:justify-evenly justify-center pt-2">

    <!-- Caja 1 -->
    <div class="panel-box bg-color2 w-80 px-14 pt-10 pb-6 rounded-2xl flex flex-col tracking-wider 2xl:w-[30%] cursor-pointer justify-center" routerLink="/encuestas" >

      <!-- Fila 1 -->
      <div class="flex-1">
        <p class="font-bold new-font mb-8 lg:mb-10 leading-tight text-center text-white">¡COMPARTE TU OPINIÓN!</p>
      </div>

      <!-- Fila 2 -->
       <div class="flex-1">
        <img src="../../../assets/images/cademito_megafono.png" alt="cademito" class="mb-4 md:mb-8 w-[50%] m-auto" style="width: 110px;" alt="">
        <!-- <img src="../../../assets/images/dialogo.png" class="mb-4 md:mb-8 w-[25%] md:w-[35%]" style="width: 110px;" alt=""> -->
       </div>

      <!-- Fila 3 -->
      <div class="flex-1">
        <p class="panel-p-text 2xl:text-lg first-line:pr-4 p-new-font text-center text-white">Revisa tus encuestas disponibles, responde y suma puntos a tu cuenta.</p>
      </div>

      <!-- Flecha -->
      <div class="flex justify-end b-arrow mt-auto"> <img class="w-1/6 max-w-10 relative left-8" src="../../../assets/images/flecha_1.png"></div>

    </div>

    <!-- Caja 2 -->
    <div class="panel-box  bg-color1 w-80 px-14 pt-10 pb-6 rounded-2xl text-white flex flex-col tracking-wider 2xl:w-[30%] cursor-pointer" routerLink="/recompensas">

      <!-- Fila 1 -->
      <div class="flex-1">
        <p class="font-bold new-font-white text-center mb-8 lg:mb-16 leading-tight" >¡CANJEA TUS PUNTOS!</p>
      </div>


      <div class="height-fix">
        <!-- Fila 2 -->
        <div class="flex-1">
          <img src="../../../assets/images/cademito_recompenzas.png" class="mb-4 md:mb-8 w-[50%] m-auto" alt="">
        </div>

        <!-- Fila 3 -->
        <div class="flex-1">
          <p class="panel-p-text 2xl:text-lg p-new-white text-center">Acumula puntos y canjea por los premios que tenemos para ti.</p>
        </div>
      </div>


      <div class="flex justify-end b-arrow mt-auto"> <img class="w-1/6 max-w-10 relative left-8" src="../../../assets/images/flecha_1.png">
      </div>
    </div>


    <!-- <div class="panel-box bg-color3 w-80 px-14 pt-10 pb-6 rounded-2xl flex flex-col tracking-wider 2xl:w-[30%] cursor-pointer" routerLink="/usuario/actualizar"> -->
    <!-- div de arriba: mb-60 md:mb-0 para espaciado mobile footer -->

      <!-- <p class="font-bold new-font text-center mb-8 lg:mb-16 leading-tight">MANTÉN TUS DATOS ACTUALIZADOS</p>
      <img src="../../../assets/images/id.png" class="mb-4 md:mb-8 w-[25%] md:w-[35%]" style="width: 110px;" alt="">

      <p class="panel-p-text 2xl:text-lg pr-4 p-new-font">Si quieres cambiar tu contraseña o cambiaste tu correo electrónico o número de celular, acá puedes actualizar tus datos.</p>
      <div class="flex justify-end mt-auto"> <img class="w-1/6 relative left-8" src="../../../assets/images/flecha_2.png">
      </div>

    </div> -->

  </div>
