import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {HttpClient} from "@angular/common/http"
import { Router } from '@angular/router';

// Environments / Interfaces
import { environment } from 'src/app/environments/environment';
import { ResponseData } from 'src/app/shared/interfaces/services.interface';

@Injectable({
  providedIn: 'root'
})
export class SurveyUserService {

  constructor(private http:HttpClient, private router:Router) { }

  updateSurveyUser(enc_id:number){
    return this.http.post<ResponseData>(`${environment.api}/v1/encuesta-usuario/update`, {enc_id})
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  refreshOneSurvey(sid:number[]){
    const data = {"sids": sid}
    return this.http.post<ResponseData>(`${environment.api}/v1/encuesta-usuario/refresh-one`, data)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  refreshAllSurvey(sids:number[]){
    const data = {"sids": sids}
    return this.http.post<ResponseData>(`${environment.api}/v1/encuesta-usuario/refresh-all`, data)
    .pipe(
      map(res=>{
        return res;
      })
    )
  }
}
