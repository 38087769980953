<div class="form-container items-center mx-auto justify-center flex bg-[#F9FAFD] h-full">
  <ng-container  *ngIf="isloading; else isNotLoading">
    <div class=" h-80 w-72  flex rounded-xl justify-center items-center " >
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
  <ng-template #isNotLoading>
    <iframe [src]="this.urlLimeSurvey" frameborder="0" width="100%" height="100%" *ngIf="isValidSid"></iframe>
    <h1 *ngIf="!isValidSid">El link no es válido!</h1>
    <!-- <a #surveyLink [href]="iframeUrl" target="_blank"></a> -->
    <!-- <iframe src="http://s6.cademonline.cl/index.php/235526?lang=es" frameborder="0" width="100%" height="100%"></iframe> -->
  </ng-template>
</div>

