<div class="flex flex-col m-10" [formGroup]="stepForm">
    <!-- Campos para el paso 3 -->
    <mat-form-field class="w-full">
        <mat-label>¿Qué compañía de teléfono celular utiliza?</mat-label>
        <mat-select formControlName="compania_telefono">
          <mat-option *ngFor="let option of formValues?.CompaniaTelefono	; let i = index" [value]="option.ctf_id">{{option.ctf_nombre}}</mat-option>
        </mat-select>
    </mat-form-field>

    <ng-container *ngIf="stepForm?.get('compania_telefono')?.value == '11'">
      <mat-form-field class="w-full">
          <mat-label>¿Cuál?</mat-label>
          <input matInput type="text" placeholder="" formControlName="otra_compania_telefono">
      </mat-form-field>
    </ng-container>

    <mat-form-field class="w-full">
        <mat-label>¿Su plan de móvil es prepago o contrato?</mat-label>
        <mat-select formControlName="plan_movil">
            <mat-option *ngFor="let option of formValues?.PlanMovil; let i = index" [value]="i">{{option}}</mat-option>
        </mat-select>
    </mat-form-field>

    <!-- [disabled]="stepForm.get('apps_frecuentes')?.value?.includes('Ninguna')  && option !== 'Ninguna'"   -->
    <!-- Pregunta -->
    <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label [ngClass]="{'invalid-field': stepForm.get('apps_frecuentes')?.invalid && (stepForm.get('apps_frecuentes')?.touched || stepForm.get('apps_frecuentes')?.dirty)}">¿Qué tipo de apps utiliza más frecuentemente? <br> (Puede elegir más de una opción).</mat-label>
      </div>
        <section>
          <div *ngFor="let option of formValues?.TiposApps; let i = index">
          <mat-checkbox        [disabled]="stepForm.get('apps_frecuentes')?.value?.includes('12')  && option !== 'Ninguna'"  [checked]="stepForm.get('apps_frecuentes')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'apps_frecuentes','12')">
            <p class="text-base text-gray-800">{{option}}</p>
          </mat-checkbox>
          </div>
        </section>
    </div>
    <ng-container  *ngIf="stepForm?.get('apps_frecuentes')?.value.includes('11') ">
      <mat-form-field class="w-full">
          <mat-label>¿Cuál?</mat-label>
          <input matInput type="text" placeholder="" formControlName="otra_apps_frecuentes">
      </mat-form-field>
    </ng-container>

    <!-- Pregunta -->
    <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label [ngClass]="{'invalid-field': stepForm.get('red_social_frecuente')?.invalid && (stepForm.get('red_social_frecuente')?.touched || stepForm.get('red_social_frecuente')?.dirty)}">¿Cuáles son las redes sociales que utiliza más frecuentemente? <br> (Puede elegir más de una opción).</mat-label>
      </div>
      <section>
        <div *ngFor="let option of formValues?.RedSocialFrecuente; let i = index">
          <mat-checkbox   [disabled]="stepForm.get('red_social_frecuente')?.value?.includes('9')  && option !== 'Ninguna'"  [checked]="stepForm.get('red_social_frecuente')?.value?.includes(i.toString())"   [checked]="stepForm.get('red_social_frecuente')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'red_social_frecuente','9')">
            <p class="text-base text-gray-800">{{option}}</p>
          </mat-checkbox>
        </div>
       </section>
    </div>
    <ng-container  *ngIf="stepForm?.get('red_social_frecuente')?.value.includes('8') ">
      <mat-form-field class="w-full">
          <mat-label>¿Cuál?</mat-label>
          <input matInput type="text" placeholder="" formControlName="otra_red_social_frecuente">
      </mat-form-field>
    </ng-container>

    <!-- Pregunta -->
    <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label [ngClass]="{'invalid-field': stepForm.get('dispositivo_tec')?.invalid && (stepForm.get('dispositivo_tec')?.touched || stepForm.get('dispositivo_tec')?.dirty)}">¿Qué dispositivos tecnológicos utiliza con más frecuencia? <br> (Puede elegir más de una opción).</mat-label>
      </div>
      <section>
        <div *ngFor="let option of formValues?.DispositivosFrecuentes; let i = index">
          <mat-checkbox [disabled]="stepForm.get('dispositivo_tec')?.value?.includes('6')  && option !== 'Ninguno'"  [checked]="stepForm.get('dispositivo_tec')?.value?.includes(i.toString())"    [checked]="stepForm.get('dispositivo_tec')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'dispositivo_tec','6')">
            <p class="text-base text-gray-800">{{option}}</p>
          </mat-checkbox>
        </div>
       </section>
    </div>
    <ng-container  *ngIf="stepForm?.get('dispositivo_tec')?.value.includes('5') ">
      <mat-form-field class="w-full">
          <mat-label>¿Cuál?</mat-label>
          <input matInput type="text" placeholder="" formControlName="otra_dispositivo_tec">
      </mat-form-field>
    </ng-container>

    <!-- Pregunta -->
    <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label [ngClass]="{'invalid-field': stepForm.get('medios_comunicacion')?.invalid && (stepForm.get('medios_comunicacion')?.touched || stepForm.get('medios_comunicacion')?.dirty)}" >¿Qué medios de comunicación consume regularmente? <br> (Puede elegir más de una opción).</mat-label>
      </div>
      <section>
        <div *ngFor="let option of formValues?.MediosComunicacion; let i = index">
          <mat-checkbox [disabled]="stepForm.get('medios_comunicacion')?.value?.includes('6')  && option !== 'Ninguno'"  [checked]="stepForm.get('medios_comunicacion')?.value?.includes(i.toString())"   [checked]="stepForm.get('medios_comunicacion')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'medios_comunicacion','6')">
            <p class="text-base text-gray-800">{{option}}</p>
          </mat-checkbox>
        </div>
       </section>
    </div>
    <ng-container  *ngIf="stepForm?.get('medios_comunicacion')?.value.includes('5') ">
      <mat-form-field class="w-full">
          <mat-label>¿Cuál?</mat-label>
          <input matInput type="text" placeholder="" formControlName="otra_medios_comunicacion">
      </mat-form-field>
    </ng-container>

</div>
