<div mat-dialog-content class="" >
  <div class="flex justify-end h-5">
    <p mat-dialog-close class="hover:font-bold text-xl cursor-pointer">✕</p>
  </div>

    <mat-dialog-content>
      <div class="md:flex overflow-hidden">

        <h3 mat-dialog-title class="block md:hidden text-center">¡Bienvenido!</h3>
        <div class="flex flex-1 justify-center items-center p-0">
          <img src="../../../assets/images/cademito_2.png" alt="cademito"
          class="cademito " style="width: 350px; display: block; margin: 0 auto;">
        </div>

        <div class="flex-1 md:pr-4 text-center md:text-left p-0 md:pl-5 md:relative md:left-7">

          <h2 mat-dialog-title class="modal-title hidden md:block mb-7">¡Bienvenido!</h2>

          <div class="pr-0 md:pr-10">
            <h4 class="text-sm font-medium">¡Nos alegra mucho que te hayas unido a Cadem Online!</h4>

            <h4 class="text-sm font-medium">Para partir, queremos conocerte mejor e invitarte a responder tu primera encuesta.
              No te tomará más de 3 minutos y podrás sumar los primeros 100 puntos a tu cuenta.</h4>

            <h4 class="text-sm font-medium mb-8 md:whitespace-nowrap">¡Gracias por ser parte de nuestra comunidad!</h4>
          </div>


          <button mat-button cdkFocusInitial class="modal-button whitespace-nowrap" (click)="navigateToSurveys()">Ir a encuesta</button>
        </div>
      </div>
    </mat-dialog-content>

    <!-- </footer> -->
    <!-- <mat-dialog-actions align="end">
    </mat-dialog-actions> -->
</div>

