import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//guards
import { AuthGuard } from './core/guard/auth.guard';
import { FirstSurveyGuard } from './core/guard/firstSurvey.guard';

//components
import { LayoutLoginComponent } from './shared/layout/login-layout/layout-login.component';
import { DefaultLayoutComponent } from './shared/layout/default-layout/default-layout.component';
import { LoginComponent } from './public/login/login.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { RegisterComponent } from './public/register/register.component';
import { RedirectToLimeSurveyComponent } from './public/redirect-to-limesurvey/redirect-to-limesurvey.component';
import { HomeComponent } from './private/home/home.component';
import { HistoryComponent } from './private/history/history.component';
import { RewardsComponent } from './private/rewards/rewards.component';
import { SurveyComponent } from './private/survey/survey.component';
import { SurveysComponent } from './private/surveys/surveys.component';

import { UserComponent } from './private/user/user.component';
import { UserUpdateComponent } from './private/user/user-update/user-update.component';

import { FirstSurveyComponent } from './private/surveys/containers/first-survey/first-survey.component';
import { RewardIdComponent } from './private/rewards/reward-id/reward-id.component';



const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent, //instancia los componentes que tenga como hijo
    children: [
      { path: '', component: HomeComponent, canActivate: [AuthGuard]},
      { path: 'firstSurvey', component: FirstSurveyComponent, canActivate: [AuthGuard]},
      { path: 'historial', component: HistoryComponent, canActivate: [AuthGuard]},
      { path:'recompensas',  canActivate: [AuthGuard], 
    children:[
            { path:'', component:RewardsComponent, canActivate: [AuthGuard] },
            { path:':id', component:RewardIdComponent, canActivate: [AuthGuard] },

    ]
    },

      { path:'encuesta', component:SurveyComponent, canActivate: [AuthGuard] },
      { path: 'encuestas' ,component: SurveysComponent, canActivate:[AuthGuard]},
      { path: 'usuario', component: UserComponent, canActivate: [AuthGuard],
        children: [
          {path:'actualizar', component: UserUpdateComponent}
        ]
      },


    ],
  },
  {
    path: '',
    component: LayoutLoginComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'olvidar-password', component: ForgotPasswordComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'redirect-to-limesurvey', component: RedirectToLimeSurveyComponent}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

