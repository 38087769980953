import { Component, OnDestroy } from '@angular/core';

import { SurveyService } from 'src/app/core/services/survey/survey.service';
import { SurveyUserService } from 'src/app/core/services/survey/surveyUser.service';
import { UserService } from 'src/app/core/services/user/user.service';

import { FirstSurvey, Survey } from 'src/app/shared/interfaces/survey.interface';

import { GeneralUtils } from 'src/app/shared/utils/general.utils';
import { NgxSpinnerService } from "ngx-spinner";

import { FirstSurveyComponent } from './containers/first-survey/first-survey.component';
import { MatDialog } from '@angular/material/dialog';
import { AppSurveyService } from 'src/app/core/services/survey/appSurvey.service';
import { ResponseData, SurveyData } from 'src/app/shared/interfaces/services.interface';

import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class SurveysComponent implements OnDestroy {

  isloading = true
  dataSurveys: Survey[] = []
  userStatus: number|null = 0
  firstSurvey: FirstSurvey | null = null;
  private intervals: any[] = [];  

  constructor(
    private surveyUserService: SurveyUserService,

    private surveyService: SurveyService,
    private generalUtils: GeneralUtils,
    private userService: UserService,
    private dialog: MatDialog,
    private appSurveyService: AppSurveyService,

    private spinner: NgxSpinnerService,
    private dateAdapter: DateAdapter<Date>,
    private route: ActivatedRoute,
    private router: Router,
    ) { }

    ngOnInit(): void {
      this.getStatus();
      this.dateAdapter.setLocale('es-ES'); 
      this.dateAdapter.getDayOfWeekNames = () => ['L', 'M', 'M', 'J', 'V', 'S', 'D']; 
      this.startIntervals();
      setTimeout(() => {
        this.userGetEncuestas();
      }, 2000);
    }
    
    startIntervals() {
      const interval1 = setInterval(async () => {
        await this.refreshAllSurveys();
      }, 60000);
      this.intervals.push(interval1);
    }

    clearAllIntervals() {
      this.intervals.forEach(interval => clearInterval(interval));
    }

    ngOnDestroy() {
      this.clearAllIntervals();
    }

    userGetEncuestas(){
      this.surveyService.getByUser().subscribe(res=>{
        if(res.status == 200){
          this.dataSurveys = res.data
          this.isloading = false
        }else{
          this.generalUtils.Toast.fire({
            icon: 'error',
            title: res.msg,
            timer: 2000
          })
          this.isloading = false
        }
        this.isloading = false
      })
    }

    goToSurvey(enc_id:number, enc_url_lime:string, eus_token:string){
      const urlLime =`${enc_url_lime}&token=${eus_token}`

      this.surveyUserService.updateSurveyUser(enc_id).subscribe(res=>{
        if(res.status == 200){
          window.open(urlLime, '_blank')
        }else{
          this.generalUtils.Toast.fire({
            icon: 'error',
            title: res.msg,
            timer: 2000
          })
        }
      })
    }

    async refreshAllSurveys(){
      this.surveyUserService.refreshAllSurvey(this.dataSurveys.map(survey=>survey.sid)).subscribe(res=>{
        if (res.status == 200){
          this.userGetEncuestas();
          this.getUserPoints()
        }else{
        }
      })

    }

    getUserPoints() {
      this.userService.getPoints().subscribe(res => {
        if (res.status === 200) {
          this.userService.updatePoints(res.data);
        }
      });
    }

    getStatus(){
      this.isloading = true
      this.userService.getUserStatus().subscribe(status => {
        this.userStatus = status;
        if(this.userStatus != 3){
          this.userGetEncuestas()
        }else{
          if (this.route.snapshot.queryParams['firstSurveyDisplay']) {
            this.getFirstSurvey(true);
            this.removeFirstSurveyDisplayParam();
          } else {
            this.getFirstSurvey();
          }
        }
      });
    }

    goToFirstSurvey(){
      const dialogRef  = this.dialog.open(FirstSurveyComponent, {
        width: '95vw',  
        maxWidth: '95vw',  
        height: '800px',
        disableClose: true,
        data: {"encuesta" : this.firstSurvey?.aen_json_encuesta, "titulo" : this.firstSurvey?.aen_titulo}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.send){
          this.isloading = true
          this.getStatus();
        }
      });
    }

    getFirstSurvey(openModal: boolean = false) {
      const data:SurveyData = {aen_id: 1}
      this.appSurveyService.getOne(data).subscribe((res:ResponseData) => {
        this.isloading = false
        if(res.status == 200) {
          this.firstSurvey = res.data;
          if (openModal) {
            this.goToFirstSurvey();
          }
        }
      });
    }
    removeFirstSurveyDisplayParam() {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { firstSurveyDisplay: null },
        queryParamsHandling: 'merge'
      });
    }
}
