import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// services
import { UserService } from 'src/app/core/services/user/user.service';
import { IpInfoService } from 'src/app/core/services/system/ip-info.service';
// interfaces
import { RegisterData, ResponseData } from 'src/app/shared/interfaces/services.interface';
// utils
import { GeneralUtils } from 'src/app/shared/utils/general.utils';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  formulario: FormGroup;
  formularioValidarCuenta: FormGroup;
  emailUser ='';
  statusForm = 1;
  loading: boolean = false;
  userCountry : string | null = null;
  hidePassword = true;
  hideRepeatedPassword = true;
  // Constructor formulario
  constructor(private fb: FormBuilder, private userService: UserService, private router: Router, private generalUtils: GeneralUtils, private activateRuote: ActivatedRoute, private ipInfoService:IpInfoService) {
    this.formulario = this.fb.group({
      usu_nombre: ['', Validators.required],
      usu_apellido: ['', Validators.required],
      usu_mail: ['', [Validators.required, Validators.email]],
      // usu_fono: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
      // usu_cod_area: ['+56', Validators.required],
      usu_password: ['', [Validators.required, Validators.minLength(6), this.generalUtils.containUppercase()]],
      usu_repeated_password: ['', Validators.required]
    }, {
      validator: this.generalUtils.passwordMatchValidator
    });

    this.formularioValidarCuenta = this.fb.group({
      usu_codigo: ['', [Validators.required]]});
  }
  ngOnInit(): void {
    // Obtiene el país del usuario
    this.ipInfoService.getCountryByIP().subscribe(({country}) => {
      this.userCountry = country;
    }, error => {
      this.ipInfoService.getIp().subscribe(data => {
        this.userCountry = data.ip
      });
    });
    this.activateRuote.queryParams.subscribe(params => {
      const status = params['status'];
      if(status) {this.statusForm = status} else {this.statusForm = 1}
      // Imprime el valor del parámetro tkey en la consola
      // Aquí puedes hacer lo que necesites con el valor del parámetro tkey
    });   
  }

  // Consulta API para registrar usuario
  userRegister(data: RegisterData) {
    if (this.userCountry == 'CL' ) {
      data.usu_pais_registro = this.userCountry;
      this.userService.register(data).subscribe((res: ResponseData)=>{
        if(res.status === 200){
          this.emailUser = data.usu_mail;
          this.loading = false;
          this.statusForm = 2;
        }else{
          this.loading = false;
          this.generalUtils.Toast.fire({
            icon: 'error',
            title: res.msg || 'Error al registrar usuario.',
            timer:2500
          });
        }
      });
    } else {
      this.generalUtils.Toast.fire({
        icon: 'info',
        title: 'Ups, por el momento solo se permite registros de usuarios en Chile',
        timer: 3500
      });
      this.loading = false;
      return;
    }
  }

  // onPhoneNumberInput(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   let value = input.value;
  //   value = value.replace(/\D/g, '');
  //   input.value = value;
  //   this.formulario.get('usu_fono')?.setValue(value, { emitEvent: false });
  // }

  onSubmit() {
    this.loading = true;
    if (this.formulario.valid) {
      this.userRegister(this.formulario.value);
    }
  }

  onSubmitValidarCuenta() {
    this.formulario.reset();
    this.loading = true;
    if (this.formularioValidarCuenta.valid) {

      this.userService.confirmarDatos({...this.formularioValidarCuenta.value,email:this.emailUser}).subscribe((res: ResponseData)=>{
        if(res.status === 200){
          this.loading = false;
          this.router.navigate(['/login']);
          this.generalUtils.Toast.fire({
            icon: 'success',
            title: 'Cuenta verificada correctamente!',
            timer: 3500
          });
        }else{
          this.loading = false;
          this.generalUtils.Toast.fire({
            icon: 'error',
            title: res.msg,
            timer:2500
          });
        }
      })
    }
  }

  irVerificarCuenta(){
    this.router.navigate(['/register'], { queryParams: { status: '2' } });  }

    togglePasswordVisibility() {
      this.hidePassword = !this.hidePassword;
    }
  
    toggleRepeatedPasswordVisibility() {
      this.hideRepeatedPassword = !this.hideRepeatedPassword;
    }
}
