import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject, debounceTime } from 'rxjs';
import { UserService } from 'src/app/core/services/user/user.service';
import { DefaultLayoutComponent } from 'src/app/shared/layout/default-layout/default-layout.component';
import { GeneralUtils } from 'src/app/shared/utils/general.utils';

/** Constants used to fill up our data base. */
export interface TableData {
  id: number;
  empresa: string;
  fecha: string;
  puntos: number;
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})

export class HistoryComponent   {
  private inputSubject: Subject<string> = new Subject<string>();
  displayedColumns: string[] = ['fecha', 'actividad', 'descripción', 'giftcard','saldo'];
  dataSource = new MatTableDataSource<TableData>([]);
  cardsSource: any = [];
  total: any = {};
  loadingTable: boolean = false;

  @ViewChild('paginator1') paginator!: MatPaginator;

  filterTable = {
    "palabra": '',
    "page": 1,
    "reg_page": 5
  }


constructor( private userService: UserService
            , private defaultLayoutComponent: DefaultLayoutComponent
            , private generalUtils: GeneralUtils
) {
  this.inputSubject
  .pipe(debounceTime(500))
  .subscribe(value => {
    this.applyFilter(value);
  });
}

handlePaging(event: PageEvent) {
  this.filterTable.page = event.pageIndex + 1;
  this.filterTable.reg_page = event.pageSize;

  this.getHistory();
}

getHistory(){
  this.loadingTable = true;
  this.userService.getHistory(this.filterTable).subscribe(res=>{
    this.dataSource = new MatTableDataSource(res.data.historial)
    this.cardsSource = res.data.historial;
    this.paginator.length = res.data.pagination.cantidad;
    this.paginator.pageIndex = res.data.pagination.pagina - 1;
    this.paginator.pageSize = res.data.pagination.cantidad_pagina;
    this.loadingTable = false;
  })
}

getHistoryTotals(){
  this.userService.getHistoryTotals().subscribe(res=>{
    this.total = res.data;
  })
}

ngAfterNiewInit() {
  this.dataSource.paginator = this.paginator;
}

applyFilter(value: string) {
  this.filterTable.palabra = value.trim().toLowerCase();
  this.filterTable.page = 1;
  this.getHistory();
}

getSearchValue(event: Event) {
  const value = (event.target as HTMLInputElement).value;
  this.inputSubject.next(value);
}

ngOnInit() {
  this.defaultLayoutComponent.getUserAlerts();
  this.loadingTable = true;
  this.getHistory();
  this.getHistoryTotals();
}

  getGiftCard( idCanje: number | any ){
    // console.log('getGiftCard :: ', idCanje)
    this.userService.getGiftCard(idCanje).subscribe(res=>{
      const {status, data, msg} = res;
      if (status === 200) {
        // console.log('getGiftCard :: ', data);
        window.open(data, '_blank');
      } else {
        this.generalUtils.Toast.fire({icon: 'success', title: res.msg, timer:3000});
      }
    })
  }

}

