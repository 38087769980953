    <div class="w-full min-w-[90%] md:min-w-[500px] h-[90%] md:h-auto flex flex-col justify-center text-center">

        <mat-dialog-content class="mat-typography">
            <div [ngClass]="{'hidden': estatusUser!=0, 'block': estatusUser==0}">
              <h2>¿Necesitas ayuda?</h2>
              <p>Escríbenos tu duda y nos pondremos en contacto contigo rápidamente.</p>
              <form [formGroup]="updateForm" (ngSubmit)="enviarAyuda()" >
                <div class="pt-10">
                    <mat-form-field class="w-full" appearance="outline" >
                      <mat-label>Título</mat-label>
                      <!-- <mat-icon matSuffix>lock</mat-icon> -->

                      <input matInput type="text" formControlName="titulo">
                    </mat-form-field>
                  </div>

                  <div class="pt-2 pb-5">
                    <mat-form-field class="w-full" appearance="outline" >
                      <mat-label>Descripción</mat-label>
                      <!-- <mat-icon matSuffix>lock</mat-icon> -->

                      <textarea matInput
                      formControlName="descripcion"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="5"
                      cdkAutosizeMaxRows="5"></textarea>
                            </mat-form-field>
                  </div>

                  <div class="pb-6">
                    <button class="bg-sky-400 text-white py-2  px-10 rounded-3xl"  type="submit" >Enviar</button>
                  </div>

            </form>
            </div>
            <div class="text-center flex justify-center flex-col items-center" [ngClass]="{'hidden': estatusUser!=1, 'block': estatusUser==1}">
              <h2>¿Necesitas ayuda?</h2>
              <p>Escríbenos tu duda y nos pondremos en contacto contigo rápidamente.</p>
              <mat-spinner></mat-spinner>
            </div>
            <div [ngClass]="{'hidden': estatusUser!=2, 'block': estatusUser==2}">
              <div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md rounded-lg" role="alert">
                <div class="flex">
                  <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                  <div>
                    <p class="font-bold">Consulta enviada correctamente</p>
                    <p class="text-sm">"Este mensaje se cerrará en 3 segundos."</p>
                  </div>
                </div>
              </div>
             </div>
            <div [ngClass]="{'hidden': estatusUser!=3, 'block': estatusUser==3}">
              <div class="bg-red-100 border-t-4 border-red-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                <div class="flex">
                  <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                  <div>
                    <p class="font-bold">Hubo un problema.</p>
                    <p class="text-sm">"Este mensaje se cerrará en 3 segundos."</p>
                  </div>
                </div>
              </div>             </div>
        </mat-dialog-content>

    </div>


