<div class="w-full ">

  <div class="w-3/4 mx-auto grid grid-cols-2 gap-4 justify-between max-w-4xl">
  <!-- div de arriba: mb-60 md:mb-0 para espaciado mobile footer -->

    <!-- Cards totales -->
      <div class="col-span-1 rounded-xl bg-white shadow-lg p-3 md:p-8 text-center">
        <h2 class="text-sm md:text-lg">ENCUESTAS <br> REALIZADAS</h2>
        <p class="text-lg md:text-xl mb-1">
          <ng-container *ngIf="total.Encuesta !== undefined; else loading">
            {{ total.Encuesta }}
          </ng-container>
          <ng-template #loading>
            <div class="flex justify-center">
              <div class="h-6 w-28 bg-gray-100 rounded animate-pulse"></div>
            </div>
          </ng-template>
        </p>
      </div>
      <div class="col-span-1 rounded-xl bg-white shadow-lg p-3 md:p-8 text-center">
        <h2 class="text-sm md:text-lg">CANJES <br> REALIZADOS</h2>
        <p class="text-lg md:text-xl mb-1">
          <ng-container *ngIf="total.Canje !== undefined; else loading">
            {{ total.Canje }}
          </ng-container>
          <ng-template #loading>
            <div class="flex justify-center">
              <div class="h-6 w-28 bg-gray-100 rounded animate-pulse"></div>
            </div>
          </ng-template>
        </p>
      </div>

    <!-- Contenedor tabla (dekstop) -->
      <div class="cus-sm:hidden md:block col-span-2">
        <div class=" flex justify-around gap-6 flex-wrap ">
          <div class="rounded-xl bg-white shadow-lg p-8 text-center w-full ">

            <!-- Header div tabla -->
            <div class="grid grid-cols-2 w-full">
              <!-- Título -->
              <h2 class="flex justify-start text-2xl pl-8 pb-8">Historial</h2>

              <!-- Buscador -->
                <div class="flex justify-end">
                <p >
                  <mat-form-field appearance="outline">
                    <mat-label>Buscar</mat-label>
                    <input matInput (input)="getSearchValue($event)" placeholder="Buscar">
                    <mat-icon matSuffix>search</mat-icon>
                  </mat-form-field>
                </p>
                </div>

            </div>

            <!-- Tabla -->
            <div>
              <table mat-table [dataSource]="dataSource">

                <!-- Position Column -->
                <ng-container matColumnDef="actividad">
                  <th mat-header-cell *matHeaderCellDef class="font-bold text-center"> ACTIVIDAD </th>
                  <td mat-cell class="text-center" *matCellDef="let element"> {{element.tipo}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="fecha">
                  <th mat-header-cell *matHeaderCellDef class="font-bold text-center"> FECHA </th>
                  <td mat-cell class="text-center" *matCellDef="let element"> {{element.fecha}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="descripción">
                  <th mat-header-cell *matHeaderCellDef class="font-bold text-center"> DESCRIPCIÓN </th>
                  <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="saldo">
                <th mat-header-cell *matHeaderCellDef class="font-bold text-center"> SALDO </th>
                <td mat-cell class="text-center font-bold" *matCellDef="let element"
                  [ngClass]="{'text-green-500': element.color =='verde', 'text-red-500': element.color == 'rojo'}"> 
                  {{element.color == 'verde' ? '+ ' + element.puntos : '- ' + element.puntos}}
                </td>

                <!-- Por si acaso los valores negativos no vienen con - desde el servidor -->
                <!-- {{ element.puntos >= 0 ? '+' + element.puntos : '-' + Math.abs(element.puntos) }} -->
                </ng-container>

                <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
                  <tr  class="mat-row" *matNoDataRow>
                    <td class="mat-cell pt-20 pb-14" colspan="4">

                      <ng-container *ngIf="loadingTable else loadingComplete">

                        Cargando...
                        </ng-container>
                        <ng-template #loadingComplete>
                          No existen registros.
                        </ng-template>

                    </td>
                  </tr>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>


              <div class="pt-5 pb-2">
                <div *ngIf="loadingTable" class="w-full bg-gray-300 rounded-full h-1 overflow-hidden relative">
                  <div class="progress-bar absolute inset-0 h-full animate-progress"></div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


    <!-- Contenedor cards (mobile) -->
      <div class="cus-sm:block md:hidden col-span-2">
        <div class="grid grid-cols-1 gap-3 mt-8">

          <!-- Buscador -->
          <div class="col-span-1 flex justify-center h-20">
            <p class="w-full">
              <mat-form-field class="w-full">
                <mat-label class="text-sm">Buscar</mat-label>
                <input matInput (input)="getSearchValue($event)" class="text-sm" placeholder="Buscar">
                <mat-icon class="text-lg" matSuffix>search</mat-icon>
              </mat-form-field>
            </p>
            </div>

          <!-- Tarjetas del historial -->
          <div *ngFor="let element of cardsSource" class="rounded-xl bg-white py-3 px-6 border text-center flex justify-between items-center">
            <h2 class="text-sm font-bold !mb-0">{{element.tipo}}</h2>
            <p class="text-gray-500 text-sm !mb-0">{{element.fecha}}</p>
            <!--p class="text-sm !mb-0">{{element.descripcion}}</p-->
            <p class="text-sm font-bold !mb-0" [ngClass]="{'text-green-500': element.color =='verde', 'text-red-500': element.color == 'rojo'}">
              {{element.color == 'verde' ? '+ ' + element.puntos : '- ' + element.puntos}}
            </p>
          </div>

          <!-- Tarjeta de no datos -->
          <div *ngIf="!dataSource.data.length && !loadingTable" class="rounded-xl bg-white border p-8 text-center">
            No existen registros.
          </div>

          <!-- Tarjeta de carga -->
            <div class="pt-5 pb-2">
              <div *ngIf="loadingTable" class="w-full bg-gray-300 rounded-full h-1 overflow-hidden relative">
                <div class="progress-bar absolute inset-0 h-full animate-progress"></div>
              </div>
            </div>

        </div>



      </div>

  <!-- Paginador -->
    <div class="col-span-2 mb-5">
        <mat-paginator #paginator1
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of elements"
        (page)="handlePaging($event)">
      </mat-paginator>
    </div>


  </div>
</div>
