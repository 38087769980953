import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {HttpClient} from "@angular/common/http"
import { Router } from '@angular/router';

// Environments / Interfaces
import { environment } from 'src/app/environments/environment';
import { ResponseData } from 'src/app/shared/interfaces/services.interface';
import { AlertsResponse } from 'src/app/shared/interfaces/alerts.interface';

@Injectable({
  providedIn: 'root'
})
export class AlertasService {

  constructor(private http:HttpClient, private router:Router) { }

  getUserAlerts(): Observable<AlertsResponse> {
    return this.http.get<AlertsResponse>(`${environment.api}/v1/alerta/get_alerts_by_user`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  markAlertAsRead(): Observable<AlertsResponse> {
    return this.http.post<AlertsResponse>(`${environment.api}/v1/alerta/mark_alerts_as_read`, '')
      .pipe(
        map(res => {
          return res;
        })
      );
  }
}
