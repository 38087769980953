<div class="flex justify-center items-center mt-10">
    <mat-card>
      <mat-card-header class="flex justify-center">
        <mat-card-title class="text-center">Tus últimas 10 encuestas</mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <div class="w-full">
            <table mat-table [dataSource]="dataSurveys">
  
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Nombre </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.enc_nombre}} </td>
              </ng-container>
  
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Fecha </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.enc_fecha | date:'dd-MM-YYYY'}} </td>
              </ng-container>
  
              <ng-container matColumnDef="score">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Puntaje </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.enc_puntaje}} </td>
              </ng-container>
  
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-center"></th>
                <td mat-cell *matCellDef="let element" class="text-center">
                  <!-- <button mat-flat-button color="primary" (click)="updateUserSurvey(element.enc_id, element.enc_url_lime, element.eus_token)">Ir</button> -->
                  <button mat-flat-button color="primary" (click)="goToSurvey(element.enc_id, element.enc_url_lime, element.eus_token)">Ir</button>
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
            </table>
          </div>
      </mat-card-content>
    </mat-card>
  </div>