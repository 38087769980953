import { Component} from '@angular/core';
//components
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-basic-data',
  templateUrl: './basic-data.component.html',
  styleUrls: ['./basic-data.component.css']
})
export class BasicDataComponent extends BaseComponent{
  minDate: Date = new Date(1930, 0, 1);
  maxDate: Date = new Date; //Fecha actual


  ngOnInit(): void {
    this.sendSubtitle("Datos básicos");
    this.regionChange();
  }

  validateRut(){
    let rut = this.stepForm.controls['rut'].value;
    if (rut.length < 9) {
      return;
    }
    this.utilService.validateRut(rut).subscribe(resp => {
        if(resp.status != 200 || resp.data != true) {
            this.stepForm.controls['rut'].setValue('');
            this.generalUtils.Toast.fire({icon: 'error', title: 'Rut no valido.', timer: 1300});
        }
    });
  }

  formatAndLimitRut(value: string): string {
    //solo aceptar letras y numeros
    value = value.replace(/[^0-9kK]/g, '');
    //limitar caracteres y eliminar los que sobran
    if (value.length > 9) {
      value = value.substring(0, 9);
    }
    //agregar solo guion
    if (value.length > 1) {
      value = value.substring(0, value.length - 1) + '-' + value.substring(value.length - 1);
    }
    this.stepForm.controls['rut'].setValue(value);
    return value;
  }

  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;
    inputValue = this.formatAndLimitRut(inputValue);
    inputElement.value = inputValue;
  }

  regionChange(){
    this.stepForm.get('region')?.valueChanges.subscribe((value) => {
      this.stepForm.get('comuna')?.reset();
    })
  }

  filterComunas(){
    const region_id = this.stepForm.get('region')?.value;
    return this.formValues.Comuna.filter((comuna: any) => comuna.rgn_id == region_id);
  }

  onPhoneNumberInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    value = value.replace(/\D/g, '');
    input.value = value;
    this.stepForm.get('usu_fono')?.setValue(value, { emitEvent: false });
  }
}
