<div class="flex justify-center items-center flex-wrap">
<!-- div de arriba: mb-60 md:mb-0 para espaciado mobile footer -->

  <!-- Refresh button -->
  <!-- <div class="w-full flex justify-end pr-14 relative bottom-8 left-20 md:left-16 lg:left-16 xl:left-14">
    <i class="material-icons cursor-pointer text-color4 hover:text-color4-h" (click)="refreshAllSurveys()">refresh</i>
  </div> -->

  <ng-container *ngIf="isloading; else isNotLoading">
    <div class=" h-80 w-72  flex rounded-xl justify-center items-center " >
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>

  <ng-template #isNotLoading>
      <div class="container mx-auto">
          <div class="flex flex-wrap -mx-2">

              <div *ngIf="firstSurvey && userStatus==3" class="custom-cards text-center border min-h-[300px] rounded-2xl" style="cursor: pointer;" (click)="goToFirstSurvey()">
                <!-- Sección blanca sobre el fondo gris-->
                <div class="bg-white p-4 rounded-2xl flex flex-col items-center text-center">
                  <div class="mb-4 w-1/2">
                      <img src="assets/images/imgEncuesta2.png" alt="Imagen encuesta" class="mx-auto">
                  </div>
                  <h2 class="text-sm md:text-lg font-semibold mb-2 h2-custom-class">{{firstSurvey?.aen_titulo}}</h2>
                </div>

                <div class="flex items-center justify-center space-x-2 pt-8">
                  <img src="assets/images/reloj.PNG" style="width:30px;" alt="Imagen reloj" class="">
                  <div class="text-gray-500 mb-2 time-font">{{firstSurvey?.aen_duracion}} minutos</div>
                </div>

                <div class="flex items-center justify-center space-x-2 pt-2 pb-6">
                  <img src="assets/images/medalla.PNG" style="width:30px;" alt="Imagen medalla" class="">
                  <div class="font-bold points-font">{{firstSurvey?.aen_puntaje}} puntos</div>
                </div>
              </div>

              <!-- Tarjetas -->
              <div class="w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 px-2 mb-4" *ngFor="let survey of dataSurveys">
                <div class="custom-cards text-center border min-h-[300px] rounded-2xl" style="cursor: pointer;" (click)="goToSurvey(survey.enc_id, survey.enc_url_lime, survey.eus_token)">

                  <!-- Sección blanca sobre el fondo gris-->
                  <div class="bg-white p-4 rounded-2xl flex flex-col items-center text-center">
                    <div class="mb-4 w-1/2">
                        <img src="assets/images/imgEncuesta2.png" style="width: 100px;" alt="Imagen encuesta" class="mx-auto">
                    </div>
                    <h2 class="text-sm md:text-lg font-semibold mb-2 h2-custom-class">{{survey.enc_nombre}}</h2>
                  </div>

                  <div class="flex items-center justify-center space-x-2 pt-8">
                    <img src="assets/images/reloj.PNG" style="width:30px;" alt="Imagen reloj" class="">
                    <div class="text-gray-500 mb-2 time-font">{{ survey?.t_dcn_nombre ? survey.t_dcn_nombre + ' minutos' : 'Sin minutos definidos' }}</div>
                  </div>

                  <div class="flex items-center justify-center space-x-2 pt-2 pb-8">
                    <img src="assets/images/medalla.PNG" style="width:30px;" alt="Imagen medalla" class="">
                    <div class="font-bold points-font">{{survey.enc_puntaje}} puntos</div>
                  </div>

                </div>
              </div>

              <!-- No hay tarjetas -->
               <div *ngIf="dataSurveys.length === 0 && userStatus !=3" class="mx-auto mt-4 pt-8">
                <p class="text-blue-950 text-center px-10 !mb-0">¡Atento! Muy pronto nuevas encuestas disponibles para responder y seguir sumando puntos.</p>
                <img class="mx-auto relative right-2" src="../../../assets/images/cademito_megafono.png" style="width: 300px">
              </div>

          </div>
      </div>
    <!-- </div> -->
  </ng-template>

  </div>
