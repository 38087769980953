import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {HttpClient} from "@angular/common/http"
import { Router } from '@angular/router';

// Environments / Interfaces
import { environment } from 'src/app/environments/environment';
import { UploadFotoResponse } from 'src/app/shared/interfaces/usuario.interface';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http:HttpClient) { }

  uploadFoto( file : any ): Observable<UploadFotoResponse> {
    const formData = new FormData();
    formData.append('file_foto', file);
    return this.http.post<UploadFotoResponse>(`${environment.api}/v1/usuario/upload-foto`, formData)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  getFotoPerfil(){
    return this.http.post<any>(`${environment.api}/v1/usuario/get-foto`, {})
    .pipe(
      map(res=>{
        return res;
      })
    )

  }

}
