<div class="form-container items-center mx-auto justify-center flex bg-[#F9FAFD]">
<div class="w-[450px]">
  <div [ngClass]="{'hidden': statusForm!=1, 'block': statusForm==1}">
    <form   [formGroup]="formulario" (ngSubmit)="onSubmit()" class="form-class md:flex md:items-center w-full bg-white-opacity rounded-lg">

      <a class="cademito flex justify-center mb-6" href="http://cademonline.cl/#inicio">
        <img src="../../../assets/images/cademito.png">
      </a>

      <h2 class="text-center font-bold text-4xl">¡Bienvenido!</h2>
      <p class="text-center text-gray-600 mb-12">Regístrate y empieza a ganar puntos</p>

      <div class="w-full  mb-6">
        <input formControlName="usu_nombre" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="usu_nombre" type="text" placeholder="Nombre">
        <div *ngIf="formulario.get('usu_nombre')?.invalid && formulario.get('usu_nombre')?.touched" class="text-red-600 text-xs absolute">
          El nombre es obligatorio.
        </div>
      </div>

      <div class="w-full  mb-6">
        <input formControlName="usu_apellido" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="usu_apellido" type="text" placeholder="Apellido">
        <div *ngIf="formulario.get('usu_apellido')?.invalid && formulario.get('usu_apellido')?.touched" class="text-red-600 text-xs absolute">
          El apellido es obligatorio.
        </div>
      </div>

      <div class="w-full  mb-6">
        <input formControlName="usu_mail" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="usu_mail" type="text" placeholder="Email">
        <div *ngIf="formulario.get('usu_mail')?.invalid && formulario.get('usu_mail')?.touched" class="text-red-600 text-xs absolute">
          El email es obligatorio.
        </div>
      </div>

      <!-- <div class="w-full mb-6">
        <div class="flex">
          <input formControlName="usu_cod_area" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:border-transparent w-1/4 md:w-1/5 mr-2"
          id="usu_cod_area" type="text" value="+56" readonly>

          <input formControlName="usu_fono" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          id="usu_fono" type="text" maxlength="9" placeholder="Teléfono" (input)="onPhoneNumberInput($event)">
        </div>
        <div *ngIf="formulario.get('usu_fono')?.invalid && formulario.get('usu_fono')?.touched" class="text-red-600 text-xs absolute">
          <div *ngIf="formulario.get('usu_fono')?.errors?.['required']">
            El teléfono es obligatorio.
          </div>
          <div *ngIf="formulario.get('usu_fono')?.errors?.['pattern']">
            El teléfono solo debe contener números.
          </div>
          <div *ngIf="formulario.get('usu_fono')?.errors?.['minlength']">
            El teléfono debe tener al menos 9 dígitos.
          </div>
        </div>
      </div> -->

      <!-- <div class="w-full mb-14 relative"> -->
      <div class="w-full mb-10 relative">
        <input [type]="hidePassword ? 'password' : 'text'" formControlName="usu_password" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="usu_password" placeholder="Contraseña" />
        <button type="button" (click)="togglePasswordVisibility()" class="absolute right-4 top-3" style="width: 24px; height: 24px; border: none; background: none; cursor: pointer;">
          <mat-icon style="color: gray;">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <div *ngIf="formulario.get('usu_password')?.invalid && formulario.get('usu_password')?.touched" class="text-red-600 text-xs absolute">
          La contraseña debe contener mínimo 6 caracteres y una mayúscula.
        </div>
      </div>

      <div class="w-full mb-14 relative" *ngIf="formulario.get('usu_password')?.valid">
        <input [type]="hideRepeatedPassword ? 'password' : 'text'" formControlName="usu_repeated_password" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="usu_repeated_password" placeholder="Repetir contraseña"/>
        <button type="button" (click)="toggleRepeatedPasswordVisibility()" class="absolute right-4 top-3" style="width: 24px; height: 24px; border: none; background: none; cursor: pointer;">
          <mat-icon style="color: gray;">{{ hideRepeatedPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <div *ngIf="formulario.get('usu_password')?.value !== formulario.get('usu_repeated_password')?.value" class="text-red-600 text-xs absolute">
          Las contraseñas no coinciden.
        </div>
      </div>


      <button  type="submit" [disabled]="formulario.invalid" [ngClass]="{'disabled': formulario.invalid}" class="primary-button text-center w-9/12 sm:w-3/5 md:w-4/6 lg:w-2/3 xl:w-4/5 2xl:w-80 h-12">Regístrate</button>

      <div class="mt-4 text-center">
          <span class="text-sm text-gray-600 pr-1">¿Ya tienes una cuenta?</span>
          <a routerLink="/login" class="text-sm text-indigo-600 hover:text-indigo-500">Iniciar sesión</a>
        </div>

        <div class="mt-4 text-center">
          <p (click)="irVerificarCuenta()" class="text-sm text-indigo-600 hover:text-indigo-500 cursor-pointer">Verifica tu cuenta</p>
        </div>

      <div *ngIf="loading" class="mt-8">
        <mat-spinner *ngIf="loading" [diameter]="40" [strokeWidth]="6"></mat-spinner>
      </div>
    </form>
  </div>
  <div [ngClass]="{'hidden': statusForm!=2, 'block': statusForm==2}">
    <form   [formGroup]="formularioValidarCuenta" (ngSubmit)="onSubmitValidarCuenta()" class="form-class md:flex md:items-center mb-6 w-full bg-white-opacity rounded-lg">
      <!-- <img src="../../../assets/images/register_image.png" class="mb-6"> -->
      <h2 class="text-center font-bold text-4xl">Verifica tu cuenta</h2>
      <p class="text-center text-sm text-gray-600 ">Te hemos enviado a tu correo electrónico el código que tienes que copiar y pegar aquí.</p>

      <div class="w-full  mb-6">
        <input formControlName="usu_codigo" class="bg-gray-100 appearance-none border-2 border-gray-100 rounded-3xl w-full py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="usu_codigo" type="text" placeholder="Código">
        <div *ngIf="formulario.get('usu_codigo')?.invalid && formulario.get('usu_codigo')?.touched" class="text-red-600 text-xs absolute">
          El codigo es obligatorio.
        </div>
      </div>



      <button  type="submit" [disabled]="formularioValidarCuenta.invalid" [ngClass]="{'disabled': formulario.invalid}" class="primary-button text-center w-9/12 sm:w-3/5 md:w-4/6 lg:w-2/3 xl:w-4/5 2xl:w-80 h-12">Verificar</button>

      <div class="mt-4 text-center">
          <span class="text-sm text-gray-600 pr-1">¿Ya tienes una cuenta?</span>
          <a routerLink="/login" class="text-sm text-indigo-600 hover:text-indigo-500">Iniciar sesión</a>
        </div>

      <div *ngIf="loading" class="mt-8">
        <mat-spinner *ngIf="loading" [diameter]="40" [strokeWidth]="6"></mat-spinner>
      </div>
    </form>
  </div>
</div>

</div>

