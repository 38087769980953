import { Component } from '@angular/core';
//components
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-free-time',
  templateUrl: './free-time.component.html',
  styleUrls: ['./free-time.component.css']
})
export class FreeTimeComponent extends BaseComponent {

  ngOnInit(): void {
    this.sendSubtitle("Ocio y tiempo libre ");
  }

}
