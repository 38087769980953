<div class="flex flex-col m-10" [formGroup]="stepForm">

  <!--Pregunta 1 -->
  <div class="w-full text-start mt-5">
    <div class="text-base text-gray-800 mb-3 ml-2">
      <mat-label
        [ngClass]="{'invalid-field': stepForm.get('hobbies_actividades')?.invalid && (stepForm.get('hobbies_actividades')?.touched || stepForm.get('hobbies_actividades')?.dirty)}">¿Cuáles
        son sus principales hobbies o actividades de tiempo libre? <br> (Puede elegir más de una opción).</mat-label>
    </div>

    <section>
      <div *ngFor="let option of formValues?.Hobbies; let i = index;">
        <mat-checkbox [disabled]="stepForm.get('hobbies_actividades')?.value?.includes('7')  && option !== 'Ninguno'"
          [checked]="stepForm.get('hobbies_actividades')?.value?.includes(i.toString())"
          [checked]="stepForm.get('hobbies_actividades')?.value?.includes(i.toString())" [value]="i.toString()"
          (change)="onCheckboxChange($event, 'hobbies_actividades','7')">
          <p class="text-base text-gray-800">{{option}}</p>
        </mat-checkbox>
      </div>
    </section>
  </div>
  <ng-container  *ngIf="stepForm?.get('hobbies_actividades')?.value.includes('6') ">
    <mat-form-field class="w-full">
        <mat-label>¿Cuál?</mat-label>
        <input matInput type="text" placeholder="" formControlName="otra_hobbies_actividades">
    </mat-form-field>
  </ng-container>

  <!--Pregunta 2 -->
  <div class="w-full text-start mt-5">
    <div class="text-base text-gray-800 mb-3 ml-2">
      <mat-label
        [ngClass]="{'invalid-field': stepForm.get('preferencia_lugar_tiempo_libre')?.invalid && (stepForm.get('preferencia_lugar_tiempo_libre')?.touched || stepForm.get('preferencia_lugar_tiempo_libre')?.dirty)}">¿Cuáles
        son los lugares preferidos para pasar su tiempo libre? <br> (Puede elegir más de una opción).</mat-label>
    </div>

    <section>
      <div *ngFor="let option of formValues?.PreferidoTiempoLibre; let i = index;">
        <mat-checkbox [disabled]="stepForm.get('preferencia_lugar_tiempo_libre')?.value?.includes('7')  && option !== 'Ninguno'"
          [checked]="stepForm.get('preferencia_lugar_tiempo_libre')?.value?.includes(i.toString())"
          [value]="i.toString()"
          (change)="onCheckboxChange($event, 'preferencia_lugar_tiempo_libre')">
          <p class="text-base text-gray-800">{{option}}</p>
        </mat-checkbox>
      </div>
    </section>
  </div>

  <!--Pregunta 3 -->
  <div class="w-full text-start mt-5">
    <div class="text-base text-gray-800 mb-3 ml-2">
      <mat-label
        [ngClass]="{'invalid-field': stepForm.get('preferencia_pasar_tiempo_libre')?.invalid && (stepForm.get('preferencia_pasar_tiempo_libre')?.touched || stepForm.get('preferencia_pasar_tiempo_libre')?.dirty)}">¿Con
        quién prefiere pasar tu tiempo libre? <br> (Puede elegir más de una opción).</mat-label>
    </div>

    <section>
      <div *ngFor="let option of formValues?.PreferenciaPasarTiempoLibre; let i = index;">
        <mat-checkbox [checked]="stepForm.get('preferencia_pasar_tiempo_libre')?.value?.includes(i.toString())"
          [value]="i.toString()" (change)="onCheckboxChange($event, 'preferencia_pasar_tiempo_libre')">
          <p class="text-base text-gray-800">{{option}}</p>
        </mat-checkbox>
      </div>
    </section>
  </div>

  <!--Pregunta 4 -->
  <div class="w-full text-start mt-5">
    <div class="text-base text-gray-800 mb-3 ml-2">
      <mat-label
        [ngClass]="{'invalid-field': stepForm.get('preferencia_medio_informacion_ocio_entretenimiento')?.invalid && (stepForm.get('preferencia_medio_informacion_ocio_entretenimiento')?.touched || stepForm.get('preferencia_medio_informacion_ocio_entretenimiento')?.dirty)}">¿Qué
        medio prefiere para enterarse de las opciones de ocio y entretenimiento? <br> (Puede elegir más de una opción).</mat-label>
    </div>

    <section>
      <div *ngFor="let option of formValues?.MedioTiempoLibre; let i = index;">
        <mat-checkbox [checked]="stepForm.get('preferencia_medio_informacion_ocio_entretenimiento')?.value?.includes(i.toString())"
          [value]="i.toString()" (change)="onCheckboxChange($event, 'preferencia_medio_informacion_ocio_entretenimiento')">
          <p class="text-base text-gray-800">{{option}}</p>
        </mat-checkbox>
      </div>
    </section>
  </div>

  <!--Pregunta 5 -->
  <mat-form-field class="w-full mt-5">
    <mat-label>¿Cuánto tiempo libre suele tener durante un día laboral?</mat-label>
    <mat-select formControlName="tiempo_libre_horario_laboral">
      <mat-option *ngFor="let option of formValues?.TiempoLibreLaboral; let i = index"
        [value]="i">{{option}}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Pregunta 6 -->
  <mat-form-field class="w-full mt-5">
    <mat-label>¿Le gustaría tener más tiempo libre en su rutina diaria?</mat-label>
    <mat-select formControlName="prerencia_mas_tiempo_libre">
      <mat-option *ngFor="let option of formValues?.GustariaLibreLaboral; let i = index"
        [value]="i">{{option}}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Pregunta 6 -->
  <mat-form-field class="w-full mt-5">
    <mat-label>¿Cuánto tiempo libre suele tener durante un día de fin de semana?</mat-label>
    <mat-select formControlName="tiempo_libre_fin_semana">
      <mat-option *ngFor="let option of formValues?.TiempoLibreDuranteFinSemana; let i = index"
        [value]="i">{{option}}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Pregunta 2 -->
  <!-- <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label [ngClass]="{'invalid-field': stepForm.get('vacaciones')?.invalid && (stepForm.get('vacaciones')?.touched || stepForm.get('vacaciones')?.dirty)}">Cuando tiene la opción de salir de vacaciones, ¿Cómo prefiere pasar sus vacaciones?</mat-label>
      </div>
        <section>
          <div *ngFor="let option of formValues?.Hobbies; let i = index;">
            <mat-checkbox [disabled]="stepForm.get('vacaciones')?.value?.includes('7')  && option !== 'Ninguno'"  [checked]="stepForm.get('vacaciones')?.value?.includes(i.toString())" [checked]="stepForm.get('vacaciones')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'vacaciones','7')">
              <p class="text-base text-gray-800">{{option}} </p>
            </mat-checkbox>
          </div>
        </section>
    </div> -->

  <!-- Pregunta 3 -->
  <!-- <div class="w-full text-start mt-5">
      <div class="text-base text-gray-800 mb-3 ml-2">
        <mat-label [ngClass]="{'invalid-field': stepForm.get('destino_vacaciones')?.invalid && (stepForm.get('destino_vacaciones')?.touched || stepForm.get('destino_vacaciones')?.dirty)}">¿Qué tipo de destino prefiere para vacacionar? <br> (Puede elegir más de una opción).</mat-label>
      </div>
        <section>
          <div *ngFor="let option of formValues?.Hobbies; let i = index;">
            <mat-checkbox [disabled]="stepForm.get('destino_vacaciones')?.value?.includes('7')  && option !== 'Ninguno'"  [checked]="stepForm.get('destino_vacaciones')?.value?.includes(i.toString())" [checked]="stepForm.get('destino_vacaciones')?.value?.includes(i.toString())"   [checked]="stepForm.get('destino_vacaciones')?.value?.includes(i.toString())" [value]="i.toString()" (change)="onCheckboxChange($event, 'destino_vacaciones','7')">
              <p class="text-base text-gray-800">{{option}}</p>
            </mat-checkbox>
          </div>

        </section>
    </div> -->
</div>
