import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {HttpClient, HttpHeaders} from "@angular/common/http"
import { Router } from '@angular/router';

// Environments / Interfaces
import { environment } from 'src/app/environments/environment';
import { RecoveryPasswordData } from 'src/app/shared/interfaces/services.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // agregar routes de tokens y recuperar password
  constructor(private http:HttpClient, private router: Router) { }

  getToken(){
    return localStorage.getItem('token')
  }

 

  checkToken(){
    return this.http.get(`${environment.api}/v1/usuario/check-token`, {
      headers: new HttpHeaders({'Authorization': `Bearer ${this.getToken()}`})
    })
    .pipe(
      map(res=>{return res;})
    )
  }

  recoveryPassword(data: RecoveryPasswordData){
    return this.http.post(`${environment.api}/v1/usuario/recovery-password`, data)
    .pipe(
      map(res=>{return res;})
    )
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser')
    this.router.navigate(['/login']);
    return
  }

  refreshToken(token:string){
    localStorage.setItem('token', token);
  }

}
