import { Component } from '@angular/core';
import { SurveyService } from 'src/app/core/services/survey/survey.service';
import { Survey } from 'src/app/shared/interfaces/survey.interface';
import { GeneralUtils } from 'src/app/shared/utils/general.utils';
import { DefaultLayoutComponent } from 'src/app/shared/layout/default-layout/default-layout.component';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent {
  
  dataSurveys: Survey[] = []
  constructor(
    private surveyService: SurveyService, 
    private generalUtils: GeneralUtils,
    private defaultLayoutComponent: DefaultLayoutComponent
    ) { }

    ngOnInit(): void {
      this.defaultLayoutComponent.getUserAlerts();
      this.userGetEncuestas();
    }
  
    userGetEncuestas(){
      this.surveyService.getByUser().subscribe(res=>{
        if(res.status == 200){
          this.dataSurveys = res.data
        }else{
          this.generalUtils.Toast.fire({
            icon: 'error',
            title: res.msg,
            timer: 2000
          })
        }
      })
    }
}
