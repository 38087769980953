import { Component } from '@angular/core';
//components
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-tech-comm',
  templateUrl: './tech-comm.component.html',
  styleUrls: ['./tech-comm.component.css']
})
export class TechCommComponent extends BaseComponent {

  ngOnInit(): void {
    this.sendSubtitle("Tecnología y Comunicación");
  }

}
