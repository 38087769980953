import { Component } from '@angular/core';
import { Router } from '@angular/router';
//forms
import { FormGroup, FormControl, Validators} from '@angular/forms';
//interfaces
import { User } from 'src/app/shared/interfaces/user.interface';
import { UpdateUserData, ChangePasswordData } from 'src/app/shared/interfaces/services.interface';
//services
import { UserService } from 'src/app/core/services/user/user.service';
//utils
import { GeneralUtils } from 'src/app/shared/utils/general.utils';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { DefaultLayoutComponent } from 'src/app/shared/layout/default-layout/default-layout.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.css']
})
export class UserUpdateComponent {

  updateForm: FormGroup | any = new FormGroup({});
  passwordForm: FormGroup | any = new FormGroup({});
  hidePassword= true
  hideNewPassword = true;
  hideRepeatedPassword = true;

  constructor(
    private userService: UserService,
    private generalUtils: GeneralUtils,
    private router:Router,
    private authService:AuthService,
    private defaultLayoutComponent: DefaultLayoutComponent
  ) {
    this.initForm();
  }

  showEditAlert() {
    Swal.fire({
      title: 'Oops...',
      text: 'Para editar esta información, por favor contáctenos a panel@cadem.cl',
      confirmButtonText: 'Ok'
    });
  }

  ngOnInit(): void {
    this.defaultLayoutComponent.getUserAlerts();
    this.getUser();
  }

  initForm() {
    this.updateForm = new FormGroup({
      usu_rut: new FormControl(''),
      usu_nombre: new FormControl({ value: "", disabled: true }),
      usu_apellido: new FormControl({ value: "", disabled: true }),
      usu_mail: new FormControl({ value: "", disabled: true }),
      usu_fono: new FormControl({ value: "", disabled: true }),
      sxo_id: new FormControl({ value: "", disabled: true }),
      usu_fecha_nacimiento: new FormControl(''),
      usu_actual_password: new FormControl(null,  [Validators.required, Validators.minLength(6), this.generalUtils.containUppercase()]),
      usu_new_password: new FormControl(null,  [Validators.required, Validators.minLength(6), this.generalUtils.containUppercase()]),
      usu_repeat_password: new FormControl(null,  [Validators.required, Validators.minLength(6), this.generalUtils.containUppercase()]),
    });
  }

  onSubmit() {
    if(this.updateForm.invalid){
      return;
    }
    const userValues = {...this.updateForm.value}
    if(userValues.usu_password=="")  userValues.usu_password =null;
    if(userValues.usu_repeat_password=="")  userValues.usu_repeat_password  =null;
    this.saveInfo(userValues)
  }

  getUser(){
    this.userService.getInfo().subscribe(res=>{
      if(res.status === 200){
        const userInfo = res.data as User
        this.updateForm.patchValue(userInfo);
        this.updateForm.get('usu_fecha_nacimiento')?.setValue(new Date(userInfo.usu_fecha_nacimiento));
      }else{
        this.generalUtils.Toast.fire({
          icon: 'error',
          title: res.msg
        })
      }
    })
  }

  saveInfo(value:ChangePasswordData){
    this.userService.updatePassword(value).subscribe(resp=>{

      if(resp.status==200){
        this.generalUtils.Toast.fire({
          icon: 'success',
          title: resp.msg,
          timer: 3000
        })

        this.userService.updatePassword(value)
        this.router.navigate(['/'])

      }else{
        this.generalUtils.Toast.fire({
          icon: 'error',
          title: resp.msg,
          timer: 3000
        })
      }
    })
  }


  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
  toggleNewPasswordVisibility() {
    this.hideNewPassword = !this.hideNewPassword;
  }
 

  toggleRepeatedPasswordVisibility() {
    this.hideRepeatedPassword = !this.hideRepeatedPassword;
  }
  
}
