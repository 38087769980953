import { Component } from '@angular/core';
//components
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-lifestyle',
  templateUrl: './lifestyle.component.html',
  styleUrls: ['./lifestyle.component.css']
})
export class LifestyleComponent extends BaseComponent{

  ngOnInit(): void {
    this.sendSubtitle("Estilo de vida ");
  }

}
