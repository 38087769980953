import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-first-survey-alert-modal',
  templateUrl: './first-survey-alert-modal.component.html',
  styleUrls: ['./first-survey-alert-modal.component.css']
})
export class FirstSurveyAlertModalComponent {
  firstSurveyDisplay: boolean = false;

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<FirstSurveyAlertModalComponent>) { }

    ngOnInit() {
      this.adjustModalSize();
      window.addEventListener('resize', this.adjustModalSize.bind(this));
    }

    ngOnDestroy() {
      window.removeEventListener('resize', this.adjustModalSize.bind(this));
    }

    adjustModalSize() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 569) {
        this.dialogRef.updateSize('100%', '650px'); 
      }
      else if (screenWidth >= 569 && screenWidth < 767) {
          this.dialogRef.updateSize('100%', '550px'); 

      } else if (screenWidth >= 767 && screenWidth < 960) {
        this.dialogRef.updateSize('100%', '500px'); 

      } else if (screenWidth >= 767 && screenWidth < 960) {
        this.dialogRef.updateSize('100%', '500px'); 

      } else if (screenWidth >= 960 && screenWidth < 1300) {
        this.dialogRef.updateSize('74%', '500px'); 

    } else if (screenWidth >= 1301 && screenWidth < 1650) {
      this.dialogRef.updateSize('60%', '500px'); 

      } else if  (screenWidth >= 1651 && screenWidth < 1750) {
        this.dialogRef.updateSize('50%', '500px'); 

      } else {
        this.dialogRef.updateSize('45%', '500px'); 
      }
    }

    navigateToSurveys() {
      this.firstSurveyDisplay = true;
      this.router.navigate(['/encuestas'], { queryParams: { firstSurveyDisplay: this.firstSurveyDisplay } });
      this.dialogRef.close();
      this.firstSurveyDisplay = false;
    }
  }


