import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {HttpClient} from "@angular/common/http"
import { Router } from '@angular/router';
import {  } from 'rxjs/operators';

// Environments / Interfaces
import { environment } from 'src/app/environments/environment';
import { DcanjeGetGiftCardData, DcanjeGetStockData, ResponseData } from 'src/app/shared/interfaces/services.interface';

@Injectable({
  providedIn: 'root'
})
export class DcanjeService {

  constructor(private http:HttpClient, private router:Router) { }

  getProducts(){
    return this.http.post<ResponseData>(`${environment.api}/v1/dcanje/get-list-giftcard`, {})
    .pipe(
      map(res=>{
        return res;
      })
    )
  }

  getStock(data: DcanjeGetStockData){
    return this.http.post<ResponseData>(`${environment.api}/v1/dcanje/get-stock-giftcard`, data)
    .pipe(
        map(res=>{
          return res;
        })
    )
  }

  getGiftCard(data: any){
    return this.http.post<ResponseData>(`${environment.api}/v1/dcanje/reedem-giftcard`, data)
    .pipe(
        map(res=>{
          return res;
        })
    ).toPromise()
  }

  downloadFile(url: string): void {
    this.http.get(url, { responseType: 'blob' }).subscribe((response: Blob) => {
      const blob = new Blob([response], { type: response.type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }
}
