// import { ResponseData } from './../../../../shared/interfaces/services.interface';
import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';

//forms
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors} from '@angular/forms';

//services
import { AppSurveyResponseService } from 'src/app/core/services/survey/appSurveyResponse.service';
import { AppSurveyService } from 'src/app/core/services/survey/appSurvey.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UtilService } from 'src/app/core/services/system/utils.service';
import { UserService } from 'src/app/core/services/user/user.service';

//interfaces
import { ResponseData, SurveyData } from 'src/app/shared/interfaces/services.interface';
import { FirstSurveyJson } from 'src/app/shared/interfaces/survey.interface';
import { GeneralUtils } from 'src/app/shared/utils/general.utils';
import { NgxSpinnerService } from "ngx-spinner";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BirthDateService } from '../../../../core/services/system/birthdate.service';

@Component({
  selector: 'app-first-survey',
  templateUrl: './first-survey.component.html',
  styleUrls: ['./first-survey.component.css']
})
export class FirstSurveyComponent {

  progress_value: number = 25;
  step: number = 1;

  age_limit: number = 18;

  firstSurveyForm: FormGroup = new FormGroup({});
  basic_data: FormGroup = new FormGroup({});
  lifestyle: FormGroup = new FormGroup({});
  tech_comunication: FormGroup = new FormGroup({});
  free_time: FormGroup = new FormGroup({});

  @ViewChild('formContainer', { static: false }) formContainer!: ElementRef;

  constructor(
    private appSurveyReponseService: AppSurveyResponseService,
    private appSurveyService: AppSurveyService,
    private utilService: UtilService,
    private userServie : UserService,
    private birthDateService: BirthDateService,
    private generalUtils: GeneralUtils,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<FirstSurveyComponent>,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public json_data: any
) {
}

  ngOnInit(): void {
    this.initForm();
    this.adjustModalSize();

    this.birthDateService.getValidYearBirth().subscribe((res:ResponseData)=>{
      if(res.status == 200){
        const currentYear = new Date().getFullYear();
        const limitYear = res.data.validar_edad;
        this.age_limit = currentYear - limitYear;
      }
    })
  }

  initForm() {
      //basic_data
      this.basic_data = new FormGroup({
        rut: new FormControl('', [Validators.required, Validators.maxLength(10)]),
        genero: new FormControl('', [Validators.required]),
        fecha_nacimiento: new FormControl('', [Validators.required]),
        otra_pais: new FormControl(''),
        region: new FormControl('', [Validators.required]),
        actividad : new FormControl('', [Validators.required]),
        aporte_hogar: new FormControl('', [Validators.required]),
        comuna: new FormControl('', [Validators.required]),
        nivel_educacion: new FormControl('', [Validators.required]),
        ocupacion: new FormControl('', [Validators.required]),
        nacionalidad: new FormControl('', [Validators.required]),
        otra_nacionalidad: new FormControl(''),
        usu_fono : new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]),
        usu_cod_area: new FormControl('+56', [Validators.required]),
      })
  }

  onSubmit() {
    //add spinner

    this.firstSurveyForm.markAllAsTouched();
    this.spinner.show();
    const data:FirstSurveyJson | any = {}
    Object.assign(data, this.basic_data.value);
  
    this.saveFirstSurvey(data);

  }

  saveFirstSurvey(data: FirstSurveyJson) {
    const send_data = {
      "aen_id" : 1,
      "json_encuesta_respuesta": data
    }
    // console.log('validar: ', this.basic_data.valid);
    if (!this.basic_data.valid) {
      this.generalUtils.Toast.fire({icon: 'error',title: 'Debes completar todos los campos', timer: 2000});
      this.spinner.hide();
      return;
    }
    this.appSurveyReponseService.firstSurveyResponseSave(send_data).subscribe((res:ResponseData) => {
      if(res.status == 200) {
        this.generalUtils.Toast.fire({icon: 'success',title: res.msg, timer: 2000});
        this.getUserPoints();
        this.userServie.refreshStatus(res.data);
        this.spinner.hide()
        this.dialogRef.close({ send: true });
      }else{
        this.generalUtils.Toast.fire({icon: 'error',title: res.msg, timer: 2000});
        this.spinner.hide();
      }
    });

  }

  validateRut(rut:string):boolean{
    let response:boolean = false
     this.utilService.validateRut(rut).subscribe(resp=>{
      if(resp.status != 200 || resp.data != true){
        this.generalUtils.Toast.fire({icon: 'error', title: 'Rut no valido.'})
        response = false
        return false
      }
      response = true
      return true
    })


    return response
  }

  nextStep() {
    switch (this.step) {

      case 1:
          if (this.basic_data.valid) {

            if (this.basic_data.get('fecha_nacimiento')?.value) {
              const age = this.generalUtils.calculateUserAge(this.basic_data.get('fecha_nacimiento')?.value);
              if (age < this.age_limit) {
                this.generalUtils.Toast.fire({icon: 'error',title: 'Debes tener al menos ' + this.age_limit + ' años para continuar.', timer: 2000});
                return;
              };
            };

          this.step++;
          this.progress_value += 25;
          this.scrollToTop();
        } else {
          this.basic_data.markAllAsTouched();
          this.generalUtils.Toast.fire({icon: 'error',title: 'Debes completar todos los campos', timer: 2000});
          Object.keys(this.basic_data.controls).forEach(key => {
            const controlErrors = this.basic_data.get(key)?.errors;
            if (controlErrors?.['rut'] ) {
              this.generalUtils.Toast.fire({icon: 'error',title: 'Ingresa el rut correctamente', timer: 2000});
            }
          });
        }
        break;

      case 2:
        if(this.lifestyle.valid) {
          this.step++;
          this.progress_value += 25;
          this.scrollToTop();
        }else{
          this.lifestyle.markAllAsTouched();
          this.generalUtils.Toast.fire({icon: 'error',title: 'Debes completar todos los campos', timer: 2000});
        }
        break;

      case 3:
        if(this.tech_comunication.valid) {
          this.step++;
          this.progress_value += 25;
          this.scrollToTop();
        }else{
          this.tech_comunication.markAllAsTouched();
          this.generalUtils.Toast.fire({icon: 'error',title: 'Debes completar todos los campos', timer: 2000});
        }
        break;

      case 4:
        if(this.free_time.valid) {
          this.step++;
          this.progress_value += 25;
          this.scrollToTop();
        }else{
          this.free_time.markAllAsTouched();
          this.generalUtils.Toast.fire({icon: 'error',title: 'Debes completar todos los campos', timer: 2000});
        }
        break;
    }
  }

  ngAfterViewInit(): void {
  
  }

  scrollToTop() {
    setTimeout(() => {
      this.cdRef.detectChanges();
      if (this.formContainer && this.formContainer.nativeElement) {
        this.formContainer.nativeElement.scrollTop = 0;
      }
    }, 10);
  }

  backStep() {
    this.step--;
    this.progress_value -= 25;
    this.scrollToTop();
  }

  getUserPoints() {
    this.userServie.getPoints().subscribe(res => {
      if (res.status === 200) {
        this.userServie.updatePoints(res.data);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close({ send: false });
  }

  adjustModalSize() {
    const screenWidth = window.innerWidth;
    if (screenWidth < 569) {
      this.dialogRef.updateSize('100%', '800px');
    }
    else if (screenWidth >= 569 && screenWidth < 767) {
        this.dialogRef.updateSize('100%', '800px');

    } else if (screenWidth >= 767 && screenWidth < 960) {
      this.dialogRef.updateSize('80%', '800px');

    } else if (screenWidth >= 960 && screenWidth < 1300) {
      this.dialogRef.updateSize('60%', '800px');

  } else if (screenWidth >= 1301 && screenWidth < 1650) {
    this.dialogRef.updateSize('50%', '800px');

    } else if  (screenWidth >= 1651 && screenWidth < 1750) {
      this.dialogRef.updateSize('40%', '800px');

    } else {
      this.dialogRef.updateSize('30%', '800px');
    }
  }


}
