import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UtilService } from 'src/app/core/services/system/utils.service';
import { GeneralUtils } from 'src/app/shared/utils/general.utils';

@Component({
  selector: 'app-modal-ayuda',
  templateUrl: './modal-ayuda.component.html',
  styleUrls: ['./modal-ayuda.component.css']
})
export class ModalAyudaComponent {
  readonly dialogRef = inject(MatDialogRef<ModalAyudaComponent>);
  updateForm: FormGroup | any = new FormGroup({});
  estatusUser: number = 0;
  constructor(private helpService:UtilService,private generalUtils: GeneralUtils,){
    this.updateForm = new FormGroup({
      titulo: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),

    });
  }
  enviarAyuda() {
    if (!this.updateForm.valid) {
      this.generalUtils.Toast.fire({icon: 'error',title: 'Debes completar todos los campos', timer: 2000});
      return;
    }
    this.estatusUser = 1;
    this.helpService.generarConsulta(this.updateForm.value).subscribe(
      (res:any) => {
        if(res.status === 200){
          this.estatusUser = 2;
          this.close();

        }else{
          this.estatusUser = 3;
          this.close();

        }
      },
      (error) => {
        this.estatusUser = 3;
        this.close();
  
      }
    );  
  }
  close(): void {
    setTimeout(() => {
      this.dialogRef.close();

    }, 2000);
  }

}
