import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DcanjeService } from 'src/app/core/services/system/dcanje.service';
import { ProductoDcanje } from 'src/app/shared/interfaces/services.interface';
import { DefaultLayoutComponent } from 'src/app/shared/layout/default-layout/default-layout.component';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.css']
})
export class RewardsComponent {
  isLoadingReward = true;
  listProductDjanje: { categorias: string, list: ProductoDcanje[] }[] = []
  listProductFilter: { categorias: string, list: ProductoDcanje[] }[] = []

  disableSelect = new FormControl(false);
  listCategory: string[] = []

  constructor(private router: Router, private djance: DcanjeService, private defaultLayoutComponent: DefaultLayoutComponent) {

    this.defaultLayoutComponent.getUserAlerts();

    this.djance.getProducts().subscribe((data) => {
      this.isLoadingReward = false;
      if (data.status = 200) {
        // console.log(data.data);
        const listData =  data.data.filter((item : any)=> item.categoria !== 'MASCOTAS');
        const resultado = listData.reduce((acc: any, objeto: any) => {
          if (!acc[objeto.categoria]) {
            acc[objeto.categoria] = {
              categorias: objeto.categoria,
              list: []
            };
          }

          acc[objeto.categoria].list.push(objeto);
          return acc;
        }, {});
        this.listProductDjanje = Object.values(resultado);
        this.listProductFilter = Object.values(resultado);

        this.listProductDjanje.forEach((item) => {
          if (!this.listCategory.includes(item.categorias)) {
            this.listCategory.push(item.categorias);
          }
        });


      } else {
        
      }

    });

  }



  irRuta(item: ProductoDcanje) {
    this.router.navigate(['/recompensas/' + item.id]);

  }
  onCategoryChange(event: any) {
    if (event.target.value == 'todos') {
      this.listProductFilter = this.listProductDjanje;
    } else {
      if (event.target.value) {
        this.listProductFilter = this.listProductDjanje.filter((item) => {
          return item.categorias === event.target.value;
        });
      }
    }
  }

  getIconCategory(categoria: string) {
    switch (categoria) {
      case 'GRANDES TIENDAS CANJES CASHBACK':
        return 'attach_money';
      case 'SUPERMERCADOS Y MINIMARKET':
        return 'shopping_cart';
      case 'GRANDES TIENDAS':
        return 'shopping_basket';
      case 'GASTRONOMÍA':
        return 'local_dining';
      case 'FERRETERIA':
        return 'build';
      case 'BELLEZA Y BIENESTAR':
        return 'spa';
      case 'VESTUARIO, CALZADO Y ACCESORIOS':
        return 'local_mall';
      case 'ENTRETENCIÓN Y TIEMPO LIBRE':
        return 'local_movies';
      case 'AUTOMOTRIZ':
        return 'directions_car';
      case 'VUELOS Y EXPERIENCIAS':
        return 'local_airport';
      case 'SERVICIOS':
        return 'supervised_user_circle';
      case 'FARMACIA Y SALUD':
        return 'local_hospital';
      case 'ÓPTICA Y ACCESORIOS':
        return 'remove_red_eye';
      case 'MASCOTAS':
        return 'pets';
    }
    return 'attach_money';
  }
}
